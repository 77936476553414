<template>
  <div class="orders-home">
    <OrdersOverview />
    <div class="container main orders-home__container">
      <DashboardHeader :title="$t('order.orders')">
        <ChecButton
          v-if="showDeleteTestOrders"
          variant="round"
          color="red"
          icon="trash"
          class="orders-home__delete-test"
          outline
          @click="handleDeleteTestOrders"
        >
          {{ $t('order.deleteTest') }}
        </ChecButton>
        <ChecButton
          variant="round"
          color="primary"
          icon="download"
          @click="handleExport"
        >
          {{ $t('order.export') }}
        </ChecButton>
      </DashboardHeader>
      <div class="orders-home__table">
        <OrdersTable />
      </div>
      <DashboardPagination state-key="orders" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { ChecButton } from '@chec/ui-library';
import { buildQueryString } from '@/lib/pagination/filters';
import { makeApiRequest } from '@/lib/api';
import actions from '@/modules/orders/store/actions';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import DashboardHeader from '@/components/DashboardHeader.vue';
import DashboardPagination from '@/components/Pagination.vue';
import OrdersOverview from '../components/OrdersOverview.vue';
import OrdersTable from '../components/OrdersTable.vue';

export default {
  name: 'OrdersHome',
  components: {
    ChecButton,
    DashboardHeader,
    DashboardPagination,
    OrdersTable,
    OrdersOverview,
  },
  mixins: [
    addNotification,
    confirm,
  ],
  computed: {
    ...mapState('orders', ['currentFilters', 'currentSearchTerm']),
    ...mapState('merchant', ['merchant']),
    ...mapGetters('orders', ['hasTestOrders']),
    showDeleteTestOrders() {
      // Check if the merchant's statistics say they have test orders
      if (this.merchant?.statistics?.test_orders) {
        return true;
      }

      // Check if there are any test orders in state (since statistics were generated)
      return this.hasTestOrders;
    },
  },
  methods: {
    ...mapActions('orders', { reloadOrders: actions.RELOAD_ORDERS }),
    async handleDeleteTestOrders() {
      if (!await this.confirm(
        this.$t('order.deleteTest'),
        this.$t('order.deleteTestConfirmation'),
      )) {
        return;
      }

      makeApiRequest('DELETE', `/v1/merchants/${this.merchant.id}/test-data`)
        .then(() => {
          this.addNotification(this.$t('order.testOrdersDeleted'));
          this.reloadOrders();
          // Reload the merchant, statistics probably changed
          this.$store.dispatch('merchant/FETCH_MERCHANT', {
            showLoading: false,
            force: true,
          }, { root: true });
        })
        .catch(() => this.addNotification(this.$t('general.error')));
    },
    async handleExport() {
      if (!await this.confirm(
        this.$t('order.export'),
        this.$t('order.exportConfirmation'),
        {
          confirmLabel: this.$t('order.export'),
        },
      )) {
        return;
      }

      const queryString = buildQueryString(this.currentFilters, this.currentSearchTerm);
      const url = `/v1/exports/orders${queryString.length ? `?${queryString}` : ''}`;

      makeApiRequest('GET', url)
        .then(({ data: { message } }) => {
          this.addNotification(message);
        });
    },
  },
};
</script>

<style lang="scss">
.orders-home {
  &__container {
    @apply px-2 pt-10;

    @screen sm {
      @apply px-10;
    }
  }

  &__delete-test {
    @apply mr-2;
  }
}
</style>
