<template>
  <ChecCard tailwind="p-4">
    <ChecHeader variant="card" :title="$t('general.details')">
      <ChecButton
        color="primary"
        variant="round"
        class="order-detail__resend-receipt"
        :disabled="resending"
        @click="handleResendReceipt"
      >
        {{ resending ? this.$t('general.sending') : this.$t('order.resendReceipt') }}
      </ChecButton>
      <ChecButton
        v-if="canCancel"
        color="primary"
        variant="round"
        class="order-detail__cancel-order"
        :disabled="cancelling"
        outline
        @click="handleCancel"
      >
        {{ cancelling ? this.$t('order.cancelling') : this.$t('order.cancelOrder') }}
      </ChecButton>
      <ChecButton
        v-if="canRefund"
        color="red"
        variant="round"
        :disabled="refunding"
        outline
        @click="handleRefund"
      >
        {{ refunding ? this.$t('general.refunding') : this.$t('general.refund') }}
      </ChecButton>
    </ChecHeader>

    <div class="order-detail__summary">
      <div>
        <h4 class="order-detail__summary-title">
          {{ $t('general.reference') }}
        </h4>
        <span class="select-all">{{ order.customer_reference }}</span>
      </div>
      <div>
        <h4 class="order-detail__summary-title">
          {{ $t('general.placed') }}
        </h4>
        <Timestamp class="order-detail__summary-placed" :timestamp="order.created" />
      </div>
    </div>

    <table class="order-detail__items">
      <thead>
        <tr>
          <th>{{ $t('general.item') }}</th>
          <th>{{ $t('general.quantity') }}</th>
          <th>{{ $t('general.amount') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="lineItem in order.order.line_items" :key="lineItem.id">
          <td>
            <div class="order-detail__image-title">
              <div class="order-detail__image-container">
                <img
                  v-if="lineItem.image"
                  :src="lineItem.image.url"
                  :alt="lineItem.image.description || lineItem.product_name"
                >
                <div v-else class="order-detail__no-image">
                  {{ $t('product.noImage') }}
                </div>
              </div>
              <div class="order-detail__title">
                <router-link
                  v-if="lineItem.product_id"
                  :to="`/products/${lineItem.product_id}`"
                  class="order-detail__item-name"
                >
                  {{ lineItem.product_name }}
                </router-link>
                <div
                  v-if="lineItem.selected_options && lineItem.selected_options.length"
                  class="order-detail__item-variants"
                >
                  <span
                    v-for="selectedOption in lineItem.selected_options"
                    :key="selectedOption.group_id"
                    class="order-detail__item-variant"
                  >
                    {{ selectedOption.group_name }}:
                    <span class="order-detail__item-variant-name">
                      {{ selectedOption.option_name }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </td>
          <td>{{ lineItem.quantity }}</td>
          <td class="order-detail__item-amount">
            {{ lineValue(lineItem.line_total_with_tax) }}
          </td>
        </tr>
      </tbody>
    </table>

    <table class="order-detail__totals">
      <tbody>
        <tr>
          <td>{{ $t('general.subtotal') }}</td>
          <td>{{ lineValue(order.order.subtotal) }}</td>
        </tr>
        <tr
          v-if="order.order.discount.length !== 0"
          class="order-detail__total"
        >
          <td>{{ `${$t('general.discount')} (${order.order.discount.code})` }}</td>
          <td>-{{ lineValue(order.order.discount.amount_saved) }}</td>
        </tr>
        <tr>
          <td>{{ shippingMethod }}</td>
          <td>{{ shippingValue }}</td>
        </tr>
        <tr>
          <td>{{ $t('general.tax') }}</td>
          <td>{{ lineValue(order.tax.amount) }}</td>
        </tr>
        <tr>
          <td>{{ $t('general.total') }}</td>
          <td class="order-detail__item-amount">
            {{ lineValue(order.order.total_with_tax) }}
          </td>
        </tr>
        <tr class="order-detail__total">
          <td>{{ $t('general.totalPaid') }}</td>
          <td class="order-detail__item-amount">
            {{ lineValue(order.order.total_paid) }}
          </td>
        </tr>
      </tbody>
    </table>
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecHeader,
} from '@chec/ui-library';
import { makeApiRequest } from '@/lib/api';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import money from '@/lib/helpers/money';
import Timestamp from '@/components/Timestamp.vue';

export default {
  name: 'OrderDetails',
  components: {
    ChecButton,
    ChecCard,
    ChecHeader,
    Timestamp,
  },
  mixins: [addNotification, confirm],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      resending: false,
      refunding: false,
      cancelling: false,
    };
  },
  computed: {
    /**
     * Whether the order can be refunded
     *
     * @returns {Boolean}
     */
    canRefund() {
      if (this.order.order.total_paid.raw === 0 || this.order.status === 'cancelled') {
        return false;
      }
      return ['partially_paid', 'paid'].includes(this.order.status_payment);
    },
    canCancel() {
      return this.order.status !== 'cancelled';
    },
    /**
     * Returns the formatted price for the shipping amount of this order
     *
     * @returns {string}
     */
    shippingValue() {
      if (!this.order.order.shipping.id) {
        return '-';
      }
      return this.money(this.order.order.shipping.price).full();
    },
    shippingMethod() {
      if (!this.order.order.shipping.description?.length) {
        return this.$t('general.shipping');
      }
      return this.$t('general.shippingMethod', { method: this.order.order.shipping.description });
    },
  },
  methods: {
    money,
    /**
     * Resend the receipt to the customer
     */
    handleResendReceipt() {
      this.resending = true;
      makeApiRequest('post', `/v1/orders/${this.order.id}/actions/resend-receipt`)
        .then(() => {
          this.addNotification(this.$t('order.receiptSent'));
        })
        .catch(() => {
          this.addNotification(this.$t('order.receiptSendFailed'), 'error');
        })
        .finally(() => {
          this.resending = false;
        });
    },
    /**
     * Refund the order, then refresh the whole order
     */
    async handleRefund() {
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('order.confirmRefund'),
      )) {
        return;
      }

      this.refunding = true;
      makeApiRequest('post', `/v1/orders/${this.order.id}/actions/refund`)
        .then(() => {
          this.addNotification(this.$t('order.refundSuccess'));
          this.$emit('order-refunded');
        })
        .catch(() => {
          this.addNotification(this.$t('order.refundFailed'), 'error');
        })
        .finally(() => {
          this.refunding = false;
        });
    },
    /**
     * Cancel the order, then refresh the whole order
     */
    async handleCancel() {
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('order.confirmCancel'),
      )) {
        return;
      }

      this.cancelling = true;
      makeApiRequest('post', `/v1/orders/${this.order.id}/actions/cancel`)
        .then(() => {
          this.addNotification(this.$t('order.cancelSuccess'));
          this.$emit('order-cancel');
        })
        .catch(() => {
          this.addNotification(this.$t('order.cancelFailed'), 'error');
        })
        .finally(() => {
          this.cancelling = false;
        });
    },
    /**
     * Verify that a value has been passed. If no amount passed return a dash,
     * otherwse return the formatted amount.
     *
     * @returns {string}
     */
    lineValue(amount) {
      if (!amount?.formatted) {
        return '-';
      }
      return this.money(amount).full();
    },
  },
};
</script>

<style lang="scss">
.order-detail {
  &__resend-receipt,
  &__cancel-order {
    @apply mr-2;
  }

  &__summary {
    @apply border rounded border-gray-300 my-4 p-4 flex items-center justify-between;

    *:last-child {
      @apply text-right;
    }
  }

  &__summary-title {
    @apply caps-xxs mb-2 leading-none;
  }

  &__summary-placed {
    @apply text-base tracking-normal;

    .timestamp__date,
    .timestamp__time {
      @apply font-normal;
    }
  }

  &__summary-reference {
    @apply font-bold;
  }

  &__items {
    @apply w-full text-left text-gray-600;

    tr th:nth-child(n+2),
    tr td:nth-child(n+2) {
      @apply text-right;
    }
  }

  &__item-amount {
    @apply text-green-600;
  }

  &__item-variants {
    @apply flex mt-2 caps-xxs font-normal;
  }

  &__item-variant {
    @apply flex;

    &:not(:last-child)::after {
      @apply text-gray-300 font-mono text-4xl relative;
      bottom: -5px; // brings into line with caps-xxs text
      content: '\2219'; // hex for &bull;
      line-height: 0;
    }
  }

  &__image-title {
    @apply flex items-center;
  }

  &__image-container {
    @include aspect-ratio(1, 1);
    min-width: 3rem;

    .order-detail__no-image {
      @apply flex justify-center items-center bg-gray-300 text-center opacity-50 text-xxs;
    }

    @apply rounded overflow-hidden mr-4;
  }

  &__item-variant-name {
    @apply ml-1 font-bold;
  }

  &__totals {
    @apply w-full mt-4;

    td:nth-child(n+2) {
      @apply text-right;
    }
  }

  &__total {
    @apply font-bold;
  }
}

.payment-status {
  &--paid {
    @apply text-green-600;
  }

  &--refunded {
    @apply text-red-600;
  }
}
</style>
