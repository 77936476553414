<template>
  <Portal to="details-pagination">
    <div v-if="loaded && totalCount > 0" class="details-pagination__nav">
      <div class="details-pagination__count">
        {{
          $t('general.xOfY', {
            x: currentItemIndex() + 1,
            y: totalCount,
          })
        }}
      </div>
      <ChecButton
        tag-type="route"
        :to="{
          name: routeName,
          params: (!previousId) ? {} : { id: previousId },
        }"
        :disabled="!previousId"
        color="secondary"
        variant="small"
        class="details-pagination__nav-link"
        :title="previousTitle"
      >
        <div v-if="previousLoading" class="details-pagination__loading-icon">
          <ChecLoading without-background />
        </div>
        <!-- Required icon due to size being different if passed as button prop  -->
        <ChecIcon v-else icon="left" class="details-pagination__nav-icon" />
      </ChecButton>
      <ChecButton
        tag-type="route"
        :to="{
          name: routeName,
          params: (!nextId) ? {} : { id: nextId },
        }"
        :disabled="!nextId"
        color="secondary"
        variant="small"
        class="details-pagination__nav-link"
        :title="nextTitle"
      >
        <div v-if="nextLoading" class="details-pagination__loading-icon">
          <ChecLoading without-background />
        </div>
        <!-- Required icon due to size being different if passed as button prop  -->
        <ChecIcon v-else icon="right" class="details-pagination__nav-icon" />
      </ChecButton>
    </div>
  </Portal>
</template>

<script>
import {
  ChecButton,
  ChecIcon,
  ChecLoading,
} from '@chec/ui-library';
import { Portal } from 'portal-vue';
import { getters, actions } from '@/lib/pagination';

import withPropDefinedState from '@/mixins/withPropDefinedState';

export default {
  name: 'DetailsPagination',
  components: {
    ChecButton,
    ChecIcon,
    ChecLoading,
    Portal,
  },
  mixins: [
    withPropDefinedState({
      state: ['totalCount', 'byContext', 'context'],
      actions: { loadAdjacentItems: actions.LOAD_ADJACENT_ITEMS },
      functionalGetters: {
        getContextualState: getters.GET_CONTEXTUAL_STATE,
        getItem: getters.GET_ITEM,
      },
    }),
  ],
  props: {
    /**
     * The current item ID
     */
    item: {
      type: String,
      required: true,
    },
    /**
     * The route name for generating pagination links. eg: orders.view
     */
    routeName: {
      type: String,
      required: true,
    },
    /**
     * Specify the "name" attribute to use in next/previous buttons. Default is "name".
     */
    nameAttribute: {
      type: String,
      default: 'name',
    },
  },
  data() {
    return {
      loaded: false,
      previousLoading: false,
      nextLoading: false,
    };
  },
  computed: {
    /**
     * Retrieves the contextual state for the item
     *
     * @returns {Object}
     */
    contextualState() {
      return this.getContextualState(this.context);
    },
    /**
     * Returns the previous item's ID based on the current item's index.
     *
     * @returns {String|null}
     */
    previousId() {
      // Ensure loading states are part of the "reactive dependencies"
      if (this.previousLoading || !this.loaded) {
        return null;
      }

      const currentIndex = this.currentItemIndex();

      if (currentIndex <= 0) {
        return null;
      }

      if (this.contextualState.items[currentIndex - 1]) {
        return this.contextualState.items[currentIndex - 1].id;
      }

      return null;
    },
    /**
     * Will return the name of the previous item in state, if available, or an empty string.
     *
     * @returns {string}
     */
    previousName() {
      if (!this.previousId) {
        return '';
      }
      const currentIndex = this.currentItemIndex();
      return this.contextualState.items[currentIndex - 1][this.nameAttribute] ?? '';
    },
    /**
     * Tooltip for the previous button
     *
     * @returns {string}
     */
    previousTitle() {
      if (this.previousName) {
        return this.$t('general.previousName', { name: this.previousName });
      }
      return this.$t('general.previous');
    },
    /**
     * Returns the next item's ID based on the current item's index.
     *
     * @returns {String|null}
     */
    nextId() {
      // Ensure loading states are part of the "reactive dependencies"
      if (this.nextLoading || !this.loaded) {
        return null;
      }

      const currentIndex = this.currentItemIndex();

      if (currentIndex === this.totalCount - 1) {
        return null;
      }

      if (this.contextualState.items[currentIndex + 1]) {
        return this.contextualState.items[currentIndex + 1].id;
      }

      return null;
    },
    /**
     * Will return the name of the next item in state, if available, or an empty string.
     *
     * @returns {string}
     */
    nextName() {
      if (!this.nextId) {
        return '';
      }
      const currentIndex = this.currentItemIndex();
      return this.contextualState.items[currentIndex + 1][this.nameAttribute] ?? '';
    },
    /**
     * Tooltip for the next button
     *
     * @returns {string}
     */
    nextTitle() {
      if (this.nextName) {
        return this.$t('general.nextName', { name: this.nextName });
      }
      return this.$t('general.next');
    },
  },
  watch: {
    async previousId(previousId) {
      if (previousId || this.currentItemIndex() <= 0) {
        return;
      }

      this.previousLoading = true;
      await this.loadAdjacentItems({ id: this.item });
      this.previousLoading = false;
    },
    async nextId(nextId) {
      if (nextId || this.currentItemIndex() === this.totalCount - 1) {
        return;
      }

      this.nextLoading = true;
      await this.loadAdjacentItems({ id: this.item });
      this.nextLoading = false;
    },
  },
  mounted() {
    this.loadAdjacentItems({ id: this.item }).then(() => {
      this.loaded = true;
    }).catch(() => {
      // NOOP
      // Sometimes we can't show the component. eg: When there are
      // too many items and we have no context as to what page it is on.
    });
  },
  methods: {
    /**
     * Retrieves the item's current index.
     *
     * This is uncached (not computed) as it relies on state changes in VueX for recalculation
     *
     * @returns {Number}
     */
    currentItemIndex() {
      return this.getItem(this.item, true);
    },
  },
};
</script>

<style lang="scss">
.details-pagination {
  &__nav {
    @apply flex items-start;
  }

  &__count {
    @apply relative caps-xxs text-gray-400 mt-2;
    // Fix for height to match with button icon.
    top: -1px;
  }

  &__loading-icon {
    @apply relative h-3 w-3;
  }

  &__nav-icon {
    @apply w-3;
  }

  &__nav-link {
    @apply ml-2;
  }
}
</style>
