<template>
  <div
    class="orders-overview"
    :class="{'orders-overview--no-orders': !hasOrders}"
  >
    <template v-if="hasOrders">
      <h2
        v-if="!loading"
        class="orders-overview__title container"
      >
        {{ $t('order.overview') }}
      </h2>
      <div class="orders-overview__stats">
        <ChecLoading v-if="loading" without-background variant="dark" />
        <template v-else>
          <StatisticsGrid :statistics="gridStats" :currency="merchant.currency" />
          <ChecLineChart
            class="orders-overview__chart"
            :datasets="graphDatasets"
            :x-labels="graphLabels"
          />
        </template>
      </div>
    </template>
    <template v-else>
      <div class="orders-overview__no-orders-content">
        <MerchantGraphic
          class="orders-overview__no-orders-graphic"
        />
        <div class="orders-overview__no-orders-title">
          {{ $t('order.noOrders.title') }}
        </div>
        <div class="orders-overview__no-orders-copy">
          {{ $t('order.noOrders.copy') }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ChecLoading } from '@chec/ui-library';
import ChecLineChart from '@chec/ui-library/dist/ChecLineChart';
import { mapActions, mapGetters, mapState } from 'vuex';
import { formatCurrency } from '@/lib/locale';
import StatisticsGrid from './OrdersOverview/StatisticsGrid.vue';
import actions from '../store/actions';
import { getters } from '../store';
import MerchantGraphic from '../assets/merchants.svg';

export default {
  name: 'OrdersOverview',
  components: {
    ChecLineChart,
    ChecLoading,
    MerchantGraphic,
    StatisticsGrid,
  },
  data() {
    return {
      months: [
        'january', 'february', 'march', 'april', 'may', 'june',
        'july', 'august', 'september', 'october', 'november', 'december',
      ],
    };
  },
  computed: {
    ...mapState('merchant', ['merchant']),
    ...mapGetters('orders', { statistics: getters.GET_STATISTICS }),
    graphDatasets() {
      if (this.loading) {
        return [];
      }

      const byMonth = this.statistics.byMonth.slice(-7);
      return [{
        label: this.$t('general.sales'),
        points: byMonth.map(({ sales }) => sales.raw),
        min: 0,
        format: (n) => formatCurrency(n, this.merchant.currency),
      }, {
        label: this.$t('order.orders'),
        min: 0,
        points: byMonth.map(({ orders }) => orders),
      }];
    },
    graphLabels() {
      if (this.loading) {
        return [];
      }

      return this.statistics.byMonth.slice(-7).map(({ month }) => (
        this.$t(`general.month.${this.months[parseInt(month, 10) - 1]}`)
      ));
    },
    gridStats() {
      if (this.loading) {
        return [];
      }

      // Compile all time stats and "this month" stats
      const { byMonth, allTime, monthlyComparison } = this.statistics;

      const thisMonth = byMonth[byMonth.length - 1];
      const month = this.$t(`general.month.${this.months[new Date().getMonth()]}`);

      // Calculate the comparison statistic (diff)
      let salesDiff = null;
      let orderDiff = null;
      if (monthlyComparison) {
        const { current, previous } = monthlyComparison;

        salesDiff = previous.sales.raw < 0.01 || current.sales.raw < 0.01
          ? null
          : (current.sales.raw / previous.sales.raw) - 1;
        orderDiff = previous.orders < 1 || current.orders < 1
          ? null
          : (current.orders / previous.orders) - 1;
      }

      return [
        {
          title: this.$t('order.salesOnMonth', { month }),
          metric: thisMonth.sales.raw,
          diff: salesDiff,
          tooltip: this.$t('order.salesOnMonthHelptip'),
          type: 'currency',
        },
        {
          title: this.$t('order.salesAllTime'),
          metric: allTime.sales.raw,
          type: 'currency',
        },
        {
          title: this.$t('order.ordersOnMonth', { month }),
          metric: thisMonth.orders,
          diff: orderDiff,
          tooltip: this.$t('order.ordersOnMonthHelptip'),
          light: true,
        },
        {
          title: this.$t('order.ordersAllTime'),
          metric: allTime.orders,
          light: true,
        },
      ];
    },
    loading() {
      return !this.statistics
        || this.statistics.allTime.orders === null
        || this.statistics.byMonth.length === 0;
    },
    hasOrders() {
      // Assume there are orders while we're loading them
      return this.loading || this.statistics.allTime.orders > 0;
    },
  },
  watch: {
    /**
     * We need a watch on the statistics here, as there can be some delay (for some reason) loading
     * information with a getter, and the initial mounted call can give us statistics for a
     * different merchant.
     */
    async statistics(newValue) {
      // New values of statistics that aren't falsey don't need to trigger a load
      if (newValue) {
        return;
      }

      this.triggerLoad();
    },
  },
  mounted() {
    if (!this.statistics) {
      this.triggerLoad();
    }
  },
  methods: {
    ...mapActions('orders', {
      loadAllTimeStats: actions.LOAD_ALL_TIME_STATISTICS,
      loadMonthlyStats: actions.LOAD_MONTHLY_STATISTICS,
      loadMonthlyComparison: actions.LOAD_MONTHLY_COMPARISON,
    }),
    async triggerLoad() {
      const promises = [];

      promises.push(this.loadAllTimeStats());

      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;

      // Load the last 7 months - we show 6 but the 7th is shifted offscreen to show progression
      const startMonth = month - 7;
      promises.push(this.loadMonthlyStats({
        startMonth: startMonth < 0 ? 12 + startMonth : startMonth,
        startYear: startMonth < 0 ? year - 1 : year,
      }));

      await Promise.all(promises);

      // Load the stats for the diff after the other stats have loaded
      this.loadMonthlyComparison(new Date());
    },
  },
};
</script>

<style lang="scss">
.orders-overview {
  @apply relative bg-gray-500 w-full pt-20;

  &__title {
    @apply text-white mx-auto mb-8 font-bold px-4;
  }

  &__stats {
    @apply relative;

    // Approximate rendered height of the graph and stat blocks when loaded
    min-height: 24rem;
  }

  &__chart {
    @apply mt-16;
  }

  &--no-orders {
    @apply py-32 px-4 bg-gray-700 bg-no-repeat bg-left-top;

    background-image: url('../assets/bg-no-orders.png');
    background-size: auto 100%;
  }

  &__no-orders-content {
    @apply text-white text-center flex flex-col justify-center items-center;
  }

  &__no-orders-graphic {
    @apply max-w-xs;

    @screen lg {
      @apply max-w-sm;
    }

  }

  &__no-orders-title {
    @apply text-4xl font-bold mt-8;
  }

  &__no-orders-copy {
    @apply text-lg max-w-lg;
  }
}
</style>
