<template>
  <table class="package-list">
    <thead>
      <tr>
        <th>Item</th>
        <th class="package-list__expires-header">
          Expires
        </th>
        <th class="package-list__downloads-header">
          Downloads
        </th>
        <th class="package-list__options-header">
          Options
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="packageDetail in packages" :key="packageDetail.id">
        <td class="package-list__file">
          {{ packageDetail.name }}
        </td>

        <td class="package-list__expires">
          <template v-if="packageDetail.access_expires === null">
            Never
          </template>
          <template v-else-if="isExpired(packageDetail)">
            Expired
          </template>
          <Timestamp
            v-else
            v-tooltip="$moment.unix(packageDetail.access_expires).format('L @ LT')"
            :timestamp="packageDetail.access_expires"
            hide-time
          />
        </td>

        <!-- "Downloads" column options -->
        <td
          v-if="packageDetail.is_access_revoked"
          class="package-list__downloads package-list__downloads--revoked"
        >
          Revoked
        </td>
        <td
          v-else-if="packageDetail.is_unlimited"
          class="package-list__downloads package-list__downloads--unlimited"
        >
          &infin;
        </td>
        <td v-else class="package-list__downloads">
          {{ packageDetail.remaining_downloads }}
        </td>

        <td class="package-list__options">
          <ChecOptionsMenu menu-placement="bottom-end" title="Show access options">
            <ChecOption
              v-if="canCopy"
              @option-selected="copyText(packageDetail.access_link)"
            >
              Copy link
            </ChecOption>
            <ChecOption
              @option-selected="manage(packageDetail)"
            >
              Manage access
            </ChecOption>
            <ChecOption
              v-if="resetable(packageDetail)"
              @option-selected="reset(packageDetail)"
            >
              {{ resetText }}
            </ChecOption>
            <ChecOption
              v-if="revokable(packageDetail)"
              destructive
              @option-selected="revoke(packageDetail)"
            >
              Revoke
            </ChecOption>
          </ChecOptionsMenu>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { ChecOption, ChecOptionsMenu } from '@chec/ui-library';
import addNotification from '@/mixins/addNotification';
import copyText from '@/mixins/copyText';
import Timestamp from '@/components/Timestamp.vue';

export default {
  name: 'PackageList',
  components: {
    Timestamp,
    ChecOption,
    ChecOptionsMenu,
  },
  mixins: [addNotification, copyText(
    'Download link has been copied to your clipboard.',
    'Failed to copy the download link. Please refresh the page and try again.',
  )],
  props: {
    downloadLimit: {
      type: Number,
      default: null,
    },
    packages: {
      type: Array,
      required: true,
    },
  },
  computed: {
    resetText() {
      if (!this.downloadLimit) {
        return 'Re-enable download';
      }

      return `Reset to ${this.downloadLimit} download${this.downloadLimit === 1 ? '' : 's'}`;
    },
  },
  methods: {
    isExpired(packageDetail) {
      return packageDetail.access_expires !== null
        && this.$moment.unix(packageDetail.access_expires).isBefore(this.$moment());
    },
    resetable(packageDetail) {
      return packageDetail.is_access_revoked
        || (this.downloadLimit && packageDetail.remaining_downloads < this.downloadLimit);
    },
    revokable(packageDetail) {
      return !packageDetail.is_access_revoked;
    },
    reset(packageDetail) {
      this.$emit('reset-package', { packageDetail });
    },
    revoke(packageDetail) {
      this.$emit('revoke-package', { packageDetail });
    },
    manage(packageDetail) {
      this.$emit('manage-package', { packageDetail });
    },
  },
};
</script>

<style lang="scss">
.package-list {
  @apply table-fixed flex-grow;

  &__expires-header {
    @apply w-32 text-right;
  }

  &__downloads-header {
    @apply w-24 text-right;
  }

  &__options-header {
    @apply w-24 text-right;
  }

  &__expires {
    @apply text-right caps-xs pt-5;
  }

  &__downloads {
    @apply text-right;
  }

  &__options {
    button {
      @apply ml-auto;
    }
  }
}
</style>
