<template>
  <ChecCard
    shadow
    tailwind="p-4 mb-4"
    inner-class="shipments"
  >
    <ChecHeader variant="card" :title="$t('order.shipments')" />
    <ChecAccordion
      v-for="(shipment, index) in orderedShipments"
      :key="shipment.id"
      class="shipments__list"
      :title="$t('order.fulfillment.shipmentNumber', { number: orderedShipments.length - index })"
      :open="orderedShipments.length === 1"
      :subtitle="shipmentSubtitle(shipment)"
    >
      <a
        v-if="shipment.tracking_url && shipment.tracking_url.length"
        :href="shipment.tracking_url"
        target="_blank"
      >
        <Shipment :shipment="shipment" />
      </a>
      <Shipment v-else :shipment="shipment" />

      <table class="shipments__items">
        <thead>
          <tr>
            <th>{{ $t('general.item') }}</th>
            <th>{{ $t('general.quantity') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in shipment.line_items"
            :key="item.id"
          >
            <td>
              {{ item.product_name }}
              <div
                v-if="getSelectedOptions(item).length"
                class="shipments__item-variants"
              >
                <span
                  v-for="selectedOption in getSelectedOptions(item)"
                  :key="selectedOption.group_id"
                  class="shipments__item-variant"
                >
                  {{ selectedOption.group_name }}: {{ selectedOption.option_name }}
                </span>
              </div>
            </td>
            <td class="shipments__item-qty">
              {{ item.fulfilled_quantity }}
            </td>
          </tr>
        </tbody>
      </table>
    </ChecAccordion>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecAccordion,
  ChecHeader,
} from '@chec/ui-library';
import Shipment from './ShipmentsCard/Shipment.vue';

export default {
  name: 'ShipmentsCard',
  components: {
    ChecCard,
    ChecAccordion,
    ChecHeader,
    Shipment,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    /**
     * Reverse shipments order
     */
    orderedShipments() {
      return this.order.fulfillment.physical.shipments.slice().reverse();
    },
  },
  methods: {
    /**
     * Format the shipped_on date
     *
     * The date format is: "27 May 2020".
     *
     * @param {number|null} shippedOn
     * @returns {string}
     */
    formatDate(shippedOn) {
      return this.$moment.unix(shippedOn).format('D MMM YYYY');
    },
    /**
     * Returns a meta subtitle for the shipment card
     *
     * @param {object} shipment
     * @returns {string}
     */
    shipmentSubtitle(shipment) {
      const reference = this.$t('order.fulfillment.reference', {
        reference: shipment.customer_reference,
      });
      const dateShipped = this.$t('order.fulfillment.dateShipped', {
        date: this.formatDate(shipment.shipped_on),
      });
      return `${reference} &bull; ${dateShipped}`;
    },
    getSelectedOptions(item) {
      // @see https://github.com/chec/api.v1/issues/1242
      // todo remove this when the API is built with proper data relationships
      if (item.variants && item.variants.length) {
        return item.variants.map((variant) => ({
          ...variant,
          group_id: variant.variant_id,
          group_name: variant.variant_name,
        }));
      }

      if (item.selected_options && item.selected_options.length) {
        return item.selected_options;
      }

      return [];
    },
  },
};
</script>

<style lang="scss">
.shipments {
  &__list {
    @apply mt-4;
  }

  &__carrier {
    @apply flex justify-between;
  }

  &__carrier-details {
    @apply inline-block text-gray-600;

    h4 {
      @apply font-bold text-sm;
    }

    span {
      @apply caps-xxs;
    }
  }

  &__carrier-logo {
    @apply self-center;
  }

  &__items {
    @apply w-full mt-4;

    tr th:nth-child(n+2),
    tr td:nth-child(n+2) {
      @apply text-right;
    }
  }

  &__item-variants {
    @apply flex mt-2 caps-xxs;
  }

  &__item-variant {
    @apply flex;

    &:not(:last-child)::after {
      @apply text-gray-300 font-mono text-4xl relative;
      bottom: -5px;
      content: '\2219'; // hex for bullet operator
      line-height: 0;
    }
  }

  &__item-qty {
    @apply text-green-600;
  }
}
</style>
