var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"statistics-grid container"},_vm._l((_vm.statistics),function(ref){
var _obj;

var title = ref.title;
var metric = ref.metric;
var diff = ref.diff;
var tooltip = ref.tooltip;
var type = ref.type;
var light = ref.light;return _c('div',{key:title,staticClass:"statistics-stat",class:( _obj = {
      'statistics-stat--light': light
    }, _obj['statistics-stat--up'] = diff !== undefined && diff > 0, _obj['statistics-stat--down'] = diff !== undefined && diff < 0, _obj )},[_c('h3',{staticClass:"statistics-stat__title"},[_vm._v(" "+_vm._s(title)+" ")]),_c('span',{staticClass:"statistics-stat__metric"},[(type === 'currency')?_c('span',{staticClass:"statistics-stat__currency",domProps:{"innerHTML":_vm._s(_vm.deEmphasizeFractionalCurrency(metric))}}):[_vm._v(" "+_vm._s(metric)+" ")],(diff)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(tooltip),expression:"tooltip"}],staticClass:"statistics-stat__diff"},[_c('ChecIcon',{attrs:{"icon":diff > 0 ? 'up-arrow' : 'down-arrow',"size":"sm"}}),_vm._v(" "+_vm._s(Math.round(diff * 100))+"% ")],1):_vm._e()],2)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }