var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DashboardTable',{staticClass:"orders-table",attrs:{"align-right-after":3,"columns":[
    _vm.$t('order.orderNumber'),
    _vm.$t('order.dateTime'),
    _vm.$t('order.status'),
    _vm.$t('general.amount') ],"filters":_vm.filters,"show-search":"","state-key":"orders"},on:{"change-filters":_vm.changeFilters}},_vm._l((_vm.orders),function(order){return _c('TableRow',{key:order.id,staticClass:"orders-table__row",class:{
      'orders-table__row--sandbox': order.sandbox,
      'orders-table__row--cancelled': order.status === 'cancelled',
    },attrs:{"id":order.id,"route-name":"orders.view"}},[_c('td',[_c('div',{staticClass:"orders-table__reference-cell"},[_c('router-link',{attrs:{"to":{ name: 'orders.view', params: { id: order.id } }}},[_c('div',{staticClass:"orders-table__customer"},[_vm._v(" "+_vm._s(_vm.customerName(order))+" "),(order.status === 'cancelled')?_c('ChecDataPill',{staticClass:"orders-table__cancelled"},[_vm._v(" "+_vm._s(_vm.$t('general.cancelled'))+" ")]):_vm._e()],1),_c('div',{staticClass:"orders-table__reference"},[(order.sandbox)?[_c('span',{staticClass:"orders-table__sandbox-label"},[_vm._v(" "+_vm._s(_vm.$t('order.sandbox'))+" ")]),_vm._v(" - ")]:_vm._e(),_vm._v(" #"+_vm._s(order.customer_reference)+" ")],2)])],1)]),_c('td',[_c('Timestamp',{attrs:{"timestamp":order.created}})],1),_c('td',[_c('ChecDataPillGroup',_vm._l((_vm.pills(order)),function(ref){
    var filled = ref.filled;
    var color = ref.color;
    var text = ref.text;
return _c('ChecDataPill',{key:text,attrs:{"filled":filled,"color":color}},[_vm._v(" "+_vm._s(text)+" ")])}),1)],1),_c('td',{staticClass:"orders-table__price"},[_vm._v(" "+_vm._s(_vm.money(order.order_value).full())+" ")])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }