<template>
  <ChecModal
    :header="$t('order.changeCustomer')"
    width="xl"
    form
    @dismiss="handleClose"
  >
    <div class="input-wrapper__row space-x-2">
      <div class="input-wrapper">
        <TextField
          v-model="firstName"
          type="text"
          name="firstName"
          :label="$t('general.firstName')"
          :variant="firstNameError ? 'error' : ''"
        />
        <span v-if="firstNameError" class="input-wrapper__error">
          {{ firstNameError }}
        </span>
      </div>
      <div class="input-wrapper">
        <TextField
          v-model="lastName"
          type="text"
          name="lastName"
          :label="$t('general.lastName')"
          :variant="lastNameError ? 'error' : ''"
        />
        <span v-if="lastNameError" class="input-wrapper__error">
          {{ lastNameError }}
        </span>
      </div>
    </div>
    <div class="input-wrapper">
      <TextField
        v-model="email"
        type="email"
        name="email"
        :label="$t('general.emailAddress')"
        :variant="emailError ? 'error' : ''"
      />
      <span v-if="emailError" class="input-wrapper__error">
        {{ emailError }}
      </span>
    </div>

    <template #toolbar>
      <ChecButton color="primary" text-only @click="handleClose">
        {{ $t('general.cancel') }}
      </ChecButton>
      <ChecButton
        button-type="submit"
        color="primary"
        :disabled="loading"
        @click.prevent="handleSubmit"
      >
        {{ loading ? $t('general.saving') : $t('general.saveChanges') }}
      </ChecButton>
    </template>
  </ChecModal>
</template>

<script>
import {
  ChecModal,
  ChecButton,
  TextField,
} from '@chec/ui-library';
import { mapActions } from 'vuex';
import actions from '@/store/actions';
import addNotification from '@/mixins/addNotification';
import { makeApiRequest } from '@/lib/api';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import customerSchema from '../../schemas/customer';

const {
  ADD_UPDATED_ORDER,
} = actions;

export default {
  name: 'CustomerEdit',
  components: {
    ChecModal,
    ChecButton,
    TextField,
  },
  mixins: [addNotification],
  props: {
    /**
     * Order object with customer info
     */
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      firstName: this.order.customer.firstname,
      lastName: this.order.customer.lastname,
      email: this.order.customer.email,
      validationErrors: null,
      loading: false,
    };
  },
  computed: {
    orderId() {
      return this.order.id;
    },
    firstNameError() {
      return this.validationErrors && this.validationErrors['customer.firstname'];
    },
    lastNameError() {
      return this.validationErrors && this.validationErrors['customer.lastname'];
    },
    emailError() {
      return this.validationErrors && this.validationErrors['customer.email'];
    },
  },
  methods: {
    handleClose(reload = false) {
      this.$router.push({ name: 'orders.view', params: { id: this.orderId } })
        .then(() => {
          if (reload) {
            // TODO implement optimistic cache updates in Vuex instead of this
            window.location.reload();
          }
        });
    },
    handleSubmit() {
      this.validationErrors = {};
      this.loading = true;
      validateSchemaRequest(
        customerSchema,
        {
          customer: {
            firstname: this.firstName,
            lastname: this.lastName,
            email: this.email,
          },
        },
        { abortEarly: false },
      )
        .then((validatedData) => makeApiRequest('put', `/v1/orders/${this.orderId}`, validatedData))
        .then(() => {
          this.addNotification(this.$t('order.customerUpdated', {
            reference: this.order.customer_reference,
          }));
          this.handleClose(true);
        })
        .catch((error) => {
          if (error.name === 'ValidationError') { // yup schema validation error
            this.validationErrors = error.errors;
            return;
          }
          this.addNotification(this.$t('order.customerUpdateFailed'), 'error');
        })
        .finally(() => { this.loading = false; });
    },
    ...mapActions('orders', [ADD_UPDATED_ORDER]),
  },
};
</script>
