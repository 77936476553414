<template>
  <ChecModal
    :header="header"
    width="xl"
    form
    @dismiss="handleClose"
  >
    <ChecLoading v-if="loading" />

    <div class="input-wrapper">
      <TextField
        v-model="addressData.name"
        type="text"
        name="name"
        :label="$t('address.name')"
        :variant="nameError ? 'error' : ''"
      />
      <span v-if="nameError" class="input-wrapper__error">
        {{ nameError }}
      </span>
    </div>
    <div class="input-wrapper">
      <TextField
        v-model="addressData.street"
        type="text"
        name="street"
        :label="$t('address.line1')"
        :variant="streetError ? 'error' : ''"
      />
      <span v-if="streetError" class="input-wrapper__error">
        {{ streetError }}
      </span>
    </div>
    <div class="input-wrapper">
      <TextField
        v-model="addressData.street2"
        type="text"
        name="street2"
        :label="$t('address.line2')"
        :variant="street2Error ? 'error' : ''"
      />
      <span v-if="street2Error" class="input-wrapper__error">
        {{ street2Error }}
      </span>
    </div>
    <div class="input-wrapper__row space-x-2">
      <div class="input-wrapper">
        <ChecDropdown
          v-if="Object.values(countriesOptions) && Object.values(countriesOptions).length"
          v-model="addressData.country"
          :label="$t('address.country')"
          name="country"
          :options="countriesOptions"
          :variant="countryError ? 'error' : ''"
        />
        <span v-if="countryError" class="input-wrapper__error">
          {{ countryError }}
        </span>
      </div>
      <div class="input-wrapper">
        <ChecDropdown
          v-if="Object.values(subdivisionOptions) && Object.values(subdivisionOptions).length"
          v-model="addressData.countyState"
          :label="$t('address.state')"
          name="state"
          :options="subdivisionOptions"
          :variant="stateError ? 'error' : ''"
        />
        <span v-if="stateError" class="input-wrapper__error">
          {{ stateError }}
        </span>
      </div>
    </div>
    <div class="input-wrapper__row space-x-2">
      <div class="input-wrapper">
        <TextField
          v-model="addressData.city"
          :label="$t('address.city')"
          name="city"
          :variant="cityError ? 'error' : ''"
        />
        <span v-if="cityError" class="input-wrapper__error">
          {{ cityError }}
        </span>
      </div>
      <div class="input-wrapper">
        <TextField
          v-model="addressData.zipCode"
          :label="$t('address.zipCode')"
          name="zipCode"
          :variant="zipCodeError ? 'error' : ''"
        />
        <span v-if="zipCodeError" class="input-wrapper__error">
          {{ zipCodeError }}
        </span>
      </div>
    </div>

    <template #toolbar>
      <ChecButton color="primary" text-only @click="handleClose">
        {{ $t('general.cancel') }}
      </ChecButton>
      <ChecButton color="primary" @click="handleSave">
        {{ $t('general.saveChanges') }}
      </ChecButton>
    </template>
  </ChecModal>
</template>

<script>
import {
  ChecButton,
  ChecDropdown,
  ChecLoading,
  ChecModal,
  TextField,
} from '@chec/ui-library';
import addNotification from '@/mixins/addNotification';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import actions from '@/store/actions';
import address from '../schemas/address';

export default {
  name: 'AddressModal',
  components: {
    ChecButton,
    ChecDropdown,
    ChecLoading,
    ChecModal,
    TextField,
  },
  mixins: [addNotification],
  props: {
    address: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    subdivisions: {
      type: Object,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      addressData: {
        name: this.address.name,
        street: this.address.street,
        street2: this.address.street_2,
        country: this.address.country || '',
        countyState: this.address.county_state || '',
        city: this.address.town_city,
        zipCode: this.address.postal_zip_code,
      },
      loading: false,
      validationErrors: null,
    };
  },
  computed: {
    /**
     * @returns {String}
     */
    country() {
      return this.addressData.country;
    },
    nameError() {
      return this.validationErrors && this.validationErrors.name;
    },
    streetError() {
      return this.validationErrors && this.validationErrors.street;
    },
    street2Error() {
      return this.validationErrors && this.validationErrors.street_2;
    },
    countryError() {
      return this.validationErrors && this.validationErrors.country;
    },
    stateError() {
      return this.validationErrors && this.validationErrors.county_state;
    },
    cityError() {
      return this.validationErrors && this.validationErrors.town_city;
    },
    zipCodeError() {
      return this.validationErrors && this.validationErrors.postal_zip_code;
    },
    /**
     * Gets a list of available countries, formatted as options for a dropdown
     *
     * @returns {object[]}
     */
    countriesOptions() {
      return Object.entries(this.countries)
        .map(([key, value]) => ({ value: key, label: value }));
    },
    /**
     * Gets a list of available states/regions/subdivisions for the currently selected country
     *
     * @returns {object[]}
     */
    subdivisionOptions() {
      const countryCode = this.addressData.country;

      if (!this.subdivisions[countryCode]) {
        return [];
      }

      return Object.entries(this.subdivisions[countryCode])
        .map(([key, value]) => ({ value: key, label: value }));
    },
  },
  watch: {
    country: {
      handler(val, oldVal) {
        if (oldVal !== val) {
          this.getRegionsForCountry(val);
        }
        // If not just mounted
        if (typeof oldVal !== 'undefined') {
          // Unselect whatever state was selected
          this.addressData.countyState = '';
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    /**
     * Validate the form data, then emit an event for the containing component to use
     * for saving the form.
     */
    handleSave() {
      const data = {
        name: this.addressData.name,
        street: this.addressData.street,
        street_2: this.addressData.street2,
        country: this.addressData.country,
        county_state: this.addressData.countyState,
        town_city: this.addressData.city,
        postal_zip_code: this.addressData.zipCode,
      };

      validateSchemaRequest(address, data, { abortEarly: false })
        .then((validatedData) => {
          // Delegate actual API save to higher up
          this.$emit('save', validatedData);
        })
        .catch((error) => {
          this.validationErrors = error.errors;
        });
    },
    /**
     * Get a list of available regions from the API for the given country code
     *
     * @param {string} countryCode
     */
    getRegionsForCountry(countryCode) {
      if (countryCode === '') {
        // Do nothing
        return;
      }

      if (this.subdivisions[countryCode]) {
        // Already exists in state
        return;
      }

      this.loading = true;
      this.$store.dispatch(actions.FETCH_SUBDIVISIONS, countryCode)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
