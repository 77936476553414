<template>
  <ChecCard tailwind="p-4" inner-class="digital-delivery">
    <ChecHeader variant="card" title="Digital delivery" />
    <ChecAccordion
      v-for="download in downloads"
      :key="download.line_item_id"
      class="digital-delivery__download"
      :title="download.product_name"
      :open="downloads.length === 1"
    >
      <div class="digital-delivery__package-list">
        <PackageList
          :download-limit="downloadLimit(download)"
          :packages="download.packages"
          @reset-package="handleResetPackage(download, $event.packageDetail)"
          @revoke-package="handleRevokePackage(download, $event.packageDetail)"
          @manage-package="handleManagePackage(download, $event.packageDetail)"
        />
      </div>
    </ChecAccordion>
    <ChecModal
      v-if="manageContext"
      header="Manage package"
      form
      @dismiss="handleDismissManageModal"
    >
      <div class="digital-delivery__manage-form">
        <div class="input-wrapper__row space-x-2">
          <div class="input-wrapper">
            <TextField
              v-model="managedDownloads"
              label="Remaining downloads"
              type="number"
              name="remaining_downloads"
            />
            <span v-if="validationErrors['remaining_downloads']" class="input-wrapper__error">
              {{ validationErrors['remaining_downloads'] }}
            </span>
          </div>
          <div class="input-wrapper">
            <ChecDatepicker v-model="managedDate" label="Expiry date" name="access_expires" />
            <span v-if="validationErrors['access_expires']" class="input-wrapper__error">
              {{ validationErrors['access_expires'] }}
            </span>
          </div>
        </div>
      </div>
      <template #toolbar>
        <ChecButton color="primary" text-only @click="handleDismissManageModal">
          Cancel
        </ChecButton>
        <ChecButton button-type="submit" color="primary" @click.prevent="handleUpdateAccess">
          Update access
        </ChecButton>
      </template>
    </ChecModal>
  </ChecCard>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import {
  ChecButton,
  ChecAccordion,
  ChecCard,
  ChecDatepicker,
  ChecHeader,
  ChecModal,
  TextField,
} from '@chec/ui-library';
import mutations from '@/store/mutations';
import addNotification from '@/mixins/addNotification';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import PackageList from './DigitalDelivery/PackageList.vue';
import digitalAccessSchema from '../schemas/digitalAccess';
import actions from '../store/actions';

export default {
  name: 'DigitalDelivery',
  components: {
    ChecButton,
    ChecAccordion,
    ChecCard,
    ChecDatepicker,
    ChecHeader,
    ChecModal,
    TextField,
    PackageList,
  },
  mixins: [addNotification],
  props: {
    downloads: {
      type: Array,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      manageContext: null,
      managedDownloads: null,
      managedDate: null,
      validationErrors: {},
    };
  },
  methods: {
    ...mapMutations({ setLoading: mutations.SET_LOADING }),
    ...mapActions('orders', [actions.SET_DIGITAL_PACKAGE_ACCESS, actions.REVOKE_DIGITAL_PACKAGE]),
    /**
     * Calculate the total number of times that each package within the download can be downloaded
     *
     * @param {Object} download
     * @returns {Number|null}
     */
    downloadLimit(download) {
      return download.lifespan.download_limit
        ? parseInt(download.lifespan.download_limit, 10)
        : null;
    },
    /**
     * Set the access rules for a specific package within a given download
     *
     * @param {Object} download
     * @param {Object} packageDetail
     * @param {Object} access
     * @returns {Promise}
     */
    setAccess(download, packageDetail, access) {
      return this[actions.SET_DIGITAL_PACKAGE_ACCESS]({
        orderId: this.orderId,
        lineItemId: download.line_item_id,
        packageId: packageDetail.id,
        access,
      })
        .then(() => {
          this.addNotification(
            `Access rules for "${packageDetail.name}" were successfully updated!`,
          );
          this.$emit('update');
        })
        .catch(() => {
          this.addNotification(
            'Sorry, we\'re having problems updating the access for this download.',
            'error',
          );
        });
    },
    /**
     * Handle a request to reset the access to a specific package within a given download
     *
     * @param download
     * @param packageDetail
     */
    handleResetPackage(download, packageDetail) {
      const { lifespan: { duration, period } } = download;

      const newExpiry = duration && period
        ? this.$moment().add(duration, period).utc().format('YYYY-MM-DD hh:mm:ss')
        : null;

      this.setAccess(download, packageDetail, {
        access_expires: newExpiry,
        remaining_downloads: this.downloadLimit(download),
      });
    },
    /**
     * Handle a request to revoke access to a specific package within a given download
     *
     * @param download
     * @param packageDetail
     */
    handleRevokePackage(download, packageDetail) {
      this[actions.REVOKE_DIGITAL_PACKAGE]({
        orderId: this.orderId,
        lineItemId: download.line_item_id,
        packageId: packageDetail.id,
      })
        .then(() => {
          this.$emit('update');
          this.addNotification(`Download "${packageDetail.name}" was successfully revoked.`);
        })
        .catch(() => this.addNotification(
          'Sorry, we\'re having problems revoking the access for this download.',
          'error',
        ));
    },
    /**
     * Handle a request to manage the access for a specific package within a given download
     *
     * @param download
     * @param packageDetail
     */
    handleManagePackage(download, packageDetail) {
      this.manageContext = { download, packageDetail };
      this.managedDate = packageDetail.access_expires
        ? this.$moment.unix(packageDetail.access_expires).format('YYYY-MM-DD')
        : null;
      this.managedDownloads = packageDetail.remaining_downloads;
    },
    /**
     * Handle a request to save the access rules entered into the form
     */
    handleUpdateAccess() {
      if (!this.manageContext) {
        return;
      }

      validateSchemaRequest(digitalAccessSchema, {
        access_expires: this.managedDate && this.managedDate !== ''
          ? this.$moment(this.managedDate, 'YYYY-MM-DD').utc().format('YYYY-MM-DD hh:mm:ss')
          : null,
        remaining_downloads: this.managedDownloads === '' ? null : this.managedDownloads,
      })
        .catch((error) => { this.validationErrors = error.errors; })
        .then((access) => this.setAccess(
          this.manageContext.download,
          this.manageContext.packageDetail,
          access,
        ))
        .then(() => this.handleDismissManageModal(true))
        .catch(() => this.addNotification(
          'Sorry, we\'re having problems updating the access for this download.',
          'error',
        ));
    },
    /**
     * Handle a request to dismiss the modal that contains the form to edit access for a package
     */
    handleDismissManageModal(changed) {
      this.manageContext = null;
      if (changed === true) {
        this.$emit('update');
      }
    },
  },
};
</script>

<style lang="scss">
.digital-delivery {
  &__download {
    @apply mt-4;
  }

  &__package-list {
    @apply flex;
  }
}
</style>
