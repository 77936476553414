<template>
  <ChecCard tailwind="p-4">
    <ChecLoading v-if="loading" />
    <ChecHeader class="pb-4" variant="card" :title="$t('order.recordManualPayment')">
      <ChecButton
        color="secondary"
        variant="round"
        @click.prevent="handleSubmit"
      >
        {{ $t('general.save') }}
      </ChecButton>
    </ChecHeader>
    <p>
      <strong>{{ manualPayment.payment_source.name }}</strong>
    </p>
    <p class="order-detail__manual-amount">
      {{ $t('general.amountValue', { value: money(manualPayment.amount).full() }) }}
    </p>
    <div class="input-wrapper">
      <TextField
        v-model="manualPaymentData.transactionId"
        :label="$t('order.transactionReferenceId')"
        name="transaction_id"
      />
    </div>
    <div class="input-wrapper">
      <ChecDatepicker
        v-model="manualPaymentData.date"
        :label="$t('general.date')"
        name="date"
      />
    </div>
    <div class="input-wrapper">
      <TextField
        v-model="manualPaymentData.time"
        :label="$t('general.time')"
        type="time"
        name="time"
      />
    </div>

    <div class="input-wrapper">
      <TextField
        v-model="manualPaymentData.notes"
        :label="$t('general.notes')"
        name="notes"
        :multiline="true"
      />
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecHeader,
  ChecCard,
  ChecButton,
  ChecLoading,
  TextField,
  ChecDatepicker,
} from '@chec/ui-library';
import addNotification from '@/mixins/addNotification';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import { makeApiRequest } from '@/lib/api';
import money from '@/lib/helpers/money';
import manualPaymentSchema from '../schemas/manualPayment';

export default {
  name: 'ManualPaymentCard',
  components: {
    ChecCard,
    ChecButton,
    ChecLoading,
    ChecHeader,
    TextField,
    ChecDatepicker,
  },
  mixins: [addNotification],
  props: {
    manualPayment: {
      type: Object,
      default: () => ({}),
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      manualPaymentData: {
        date: '',
        notes: '',
        time: '',
        transactionId: '',
      },
      loading: false,
      validationErrors: {},
    };
  },
  methods: {
    money,
    /**
     * Save manual payment, and return to the orders list
     */
    handleSubmit() {
      this.loading = true;
      this.validationErrors = {};
      validateSchemaRequest(manualPaymentSchema, this.manualPaymentData, { abortEarly: false })
        .then((validatedValue) => makeApiRequest('put', `/v1/orders/${this.order.id}/transactions/${this.manualPayment.id}`, {
          status: 'complete',
          gateway_transaction_id: validatedValue.transactionId.length
            ? validatedValue.transactionId
            : undefined,
          charge_date: validatedValue.date
            ? this.$moment(`${this.manualPaymentData.date} ${this.manualPaymentData.time}`).unix()
            : null,
          notes: validatedValue.notes.length
            ? validatedValue.notes
            : undefined,
        }))
        .then(() => {
          this.addNotification(this.$t('order.paymentSaved'));
          this.$emit('manual-payment');
        })
        .catch((error) => {
          if (error.name === 'ValidationError') {
            this.validationErrors = error.errors;
            return;
          }
          this.addNotification(this.$t('order.paymentSaveFailed'), 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.order-detail {
  &__manual-amount {
    @apply pb-4;
  }
}
</style>
