<template>
  <DashboardTable
    class="orders-table"
    :align-right-after="3"
    :columns="[
      $t('order.orderNumber'),
      $t('order.dateTime'),
      $t('order.status'),
      $t('general.amount'),
    ]"
    :filters="filters"
    show-search
    state-key="orders"
    @change-filters="changeFilters"
  >
    <TableRow
      v-for="order in orders"
      :id="order.id"
      :key="order.id"
      class="orders-table__row"
      :class="{
        'orders-table__row--sandbox': order.sandbox,
        'orders-table__row--cancelled': order.status === 'cancelled',
      }"
      route-name="orders.view"
    >
      <td>
        <div class="orders-table__reference-cell">
          <!-- Note this link is here to be tab-indexable - but the whole row is clickable -->
          <router-link :to="{ name: 'orders.view', params: { id: order.id } }">
            <div class="orders-table__customer">
              {{ customerName(order) }}
              <ChecDataPill
                v-if="order.status === 'cancelled'"
                class="orders-table__cancelled"
              >
                {{ $t('general.cancelled') }}
              </ChecDataPill>
            </div>
            <div class="orders-table__reference">
              <template v-if="order.sandbox">
                <span class="orders-table__sandbox-label">
                  {{ $t('order.sandbox') }}
                </span>
                -
              </template>
              #{{ order.customer_reference }}
            </div>
          </router-link>
        </div>
      </td>
      <td>
        <Timestamp :timestamp="order.created" />
      </td>
      <td>
        <ChecDataPillGroup>
          <ChecDataPill
            v-for="({ filled, color, text }) in pills(order)"
            :key="text"
            :filled="filled"
            :color="color"
          >
            {{ text }}
          </ChecDataPill>
        </ChecDataPillGroup>
      </td>
      <td class="orders-table__price">
        {{ money(order.order_value).full() }}
      </td>
    </TableRow>
  </DashboardTable>
</template>

<script>
import {
  ChecDataPill,
  ChecDataPillGroup,
} from '@chec/ui-library';
import crud from '@/mixins/crud';
import Timestamp from '@/components/Timestamp.vue';
import money from '@/lib/helpers/money';
import DashboardTable from '@/components/DashboardTable.vue';
import TableRow from '@/components/DashboardTable/TableRow.vue';

export default {
  name: 'OrdersTable',
  components: {
    ChecDataPill,
    ChecDataPillGroup,
    DashboardTable,
    TableRow,
    Timestamp,
  },
  mixins: [
    crud('orders', true),
  ],
  computed: {
    filters() {
      return [
        {
          name: this.$t('general.status'),
          type: 'header',
        },
        {
          name: this.$t('order.orderStatus'),
          id: 'status',
          type: 'option',
          values: [
            { value: 'open', label: this.$t('general.open') },
            { value: 'cancelled', label: this.$t('general.cancelled') },
          ],
        },
        {
          name: this.$t('order.paymentStatus.label'),
          id: 'paymentStatus',
          type: 'option',
          values: [
            { value: 'paid', label: this.$t('order.paymentStatus.paid') },
            { value: 'not_paid', label: this.$t('order.paymentStatus.not_paid') },
            { value: 'partially_paid', label: this.$t('order.paymentStatus.partially_paid') },
            { value: 'pending', label: this.$t('order.paymentStatus.pending') },
            { value: 'refunded', label: this.$t('order.paymentStatus.refunded') },
          ],
        },
        {
          name: this.$t('order.fulfillmentStatus.label'),
          id: 'fulfillmentStatus',
          type: 'option',
          values: [
            { value: 'fulfilled', label: this.$t('order.fulfillmentStatus.fulfilled') },
            { value: 'not_fulfilled', label: this.$t('order.fulfillmentStatus.not_fulfilled') },
            {
              value: 'partially_fulfilled',
              label: this.$t('order.fulfillmentStatus.partially_fulfilled'),
            },
            { value: 'returned', label: this.$t('order.fulfillmentStatus.returned') },
          ],
        },
        {
          name: this.$t('order.date'),
          id: 'created',
          type: 'date-range',
        },
      ];
    },
  },
  methods: {
    changeFilters(filters) {
      this.setFilters(filters.map((filter) => {
        const { id, value } = filter;
        if (id === 'created') {
          return {
            column: 'created',
            type: 'dateRange',
            param: value,
            filterInUse: filter,
          };
        }

        const columns = {
          status: {
            column: 'status',
            query: 'status[order]',
          },
          paymentStatus: {
            column: 'status_payment',
            query: 'status[payment]',
          },
          fulfillmentStatus: {
            column: 'status_fulfillment',
            query: 'status[fulfillment]',
          },
        };

        return {
          ...columns[id],
          type: 'exact',
          param: value,
          filterInUse: filter,
        };
      }));
    },
    customerName({ customer }) {
      if (customer) {
        const { firstname, lastname, email } = customer;
        // Put the pieces of the name together and filter out blanks
        const name = [firstname, lastname]
          .filter((part) => typeof part === 'string' && part.length > 0);

        if (name.length) {
          return name.join(' ');
        }

        if (email) {
          return email;
        }
      }

      // Shouldn't be possible, but the order reference will still appear if this is the case.
      return '';
    },
    pills({ status, status_payment: statusPayment, status_fulfillment: statusFulfillment }) {
      const colors = {
        status: {
          cancelled: 'gray',
        },
        paymentStatus: {
          not_paid: 'red',
          paid: 'green',
          partially_paid: 'orange',
          pending: 'orange',
          refunded: 'gray',
        },
        fulfillmentStatus: {
          fulfilled: 'green',
          not_fulfilled: 'red',
          partially_fulfilled: 'orange',
          returned: 'gray',
        },
      };

      return [{
        color: colors.paymentStatus[statusPayment],
        filled: status !== 'cancelled',
        text: this.$t(`order.paymentStatus.${statusPayment}`),
      }, {
        color: status === 'cancelled'
          ? colors.status.cancelled
          : colors.fulfillmentStatus[statusFulfillment],
        filled: false,
        text: this.$t(`order.fulfillmentStatus.${statusFulfillment}`),
      }];
    },
    money,
  },
};
</script>

<style lang="scss">
.orders-table {
  // TODO remove when this is fixed in the UI lib
  table {
    @apply w-full;
  }

  &__row {
    @apply cursor-pointer;

    &--sandbox {
      .orders-table__reference-cell {
        @apply p-4 border-l-4 border-hazard-gray;

        padding-left: calc(1rem - 4px);
      }

      td:first-child {
        @apply p-0 h-full;
      }
    }

    &--cancelled {
      .orders-table__reference {
        @apply line-through;
      }
    }
  }

  &__cancelled {
    @apply ml-2;
  }

  &__customer {
    @apply leading-tight text-sm mb-2;
  }

  &__reference {
    @apply caps-xxs leading-tight;
  }

  &__price {
    @apply text-green-600;
  }

  &__loading-row {
    @apply relative;

    &--loading {
      @apply py-16;
    }
  }

  &__empty-text {
    @apply text-gray-400;
  }

  &__sandbox-label {
    @apply text-orange-500;
  }
}

.filter-bar-dropdown-panel {
  &__header:first-child {
    @apply pt-2;
  }
}
</style>
