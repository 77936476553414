import {
  string, object,
} from 'yup';

export default object().shape({
  name: string().nullable(),
  street: string().nullable(),
  street_2: string().nullable(),
  country: string().nullable(),
  postal_zip_code: string().nullable(),
  county_state: string().nullable(),
  town_city: string().nullable(),
});
