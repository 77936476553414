<template>
  <div class="statistics-grid container">
    <div
      v-for="{ title, metric, diff, tooltip, type, light } in statistics"
      :key="title"
      class="statistics-stat"
      :class="{
        'statistics-stat--light': light,
        ['statistics-stat--up']: diff !== undefined && diff > 0,
        ['statistics-stat--down']: diff !== undefined && diff < 0,
      }"
    >
      <h3 class="statistics-stat__title">
        {{ title }}
      </h3>
      <span class="statistics-stat__metric">
        <span
          v-if="type === 'currency'"
          class="statistics-stat__currency"
          v-html="deEmphasizeFractionalCurrency(metric)"
        />
        <template v-else>
          {{ metric }}
        </template>
        <span
          v-if="diff"
          v-tooltip="tooltip"
          class="statistics-stat__diff"
        >
          <ChecIcon :icon="diff > 0 ? 'up-arrow' : 'down-arrow'" size="sm" />
          {{ Math.round(diff * 100) }}%
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { ChecIcon } from '@chec/ui-library';
import { formatCurrency, zeroDecimalCurrencies } from '@/lib/locale';

export default {
  name: 'StatisticsGrid',
  components: {
    ChecIcon,
  },
  props: {
    statistics: Array,
    currency: Object,
  },
  methods: {
    deEmphasizeFractionalCurrency(value) {
      const money = formatCurrency(value, this.currency);

      if (zeroDecimalCurrencies.includes(this.currency)) {
        // Custom logic for the only currencies that are non-decimal
        return money;
      }

      return money.replace(/([,.]\d+)([^\d]*)$/, '<small>$1</small>$2');
    },
  },
};
</script>

<style lang="scss">
.statistics-grid {
  @apply relative text-white px-4 mx-auto;

  @screen sm {
    @apply grid grid-cols-2 gap-4;
  }

  @screen lg {
    @apply grid-cols-4;
  }

  // Give room for the loading indicator to show. This is approx. the height of the stats when
  // rendered - to prevent layout shift
  min-height: 8.375rem;
}

.statistics-stat {
  @apply rounded-lg p-4 mb-4 bg-gray-600;

  @screen sm {
    @apply p-8 mb-0;
  }

  &__title {
    @apply text-sm mb-2;
  }

  &__metric {
    @apply text-3xl flex items-center;

    small {
      @apply text-xl;
    }
  }

  &__diff {
    @apply text-sm ml-2;

    svg {
      @apply inline-block;
      margin-right: -3px;

      // Deal with weird positioning on arrow SVGs
      margin-top: -3px;
    }
  }

  &--light {
    @apply bg-white bg-opacity-10;
  }

  &--up {
    .statistics-stat__diff {
      @apply text-green-300;
    }
  }

  &--down {
    .statistics-stat__diff {
      @apply text-red-300;
    }
  }
}
</style>
