<template>
  <ChecCard tailwind="p-4" inner-class="captured-fields">
    <ChecHeader class="pb-4" variant="card" title="Captured fields">
      <ChecButton
        v-if="hasHiddenFields"
        class="card__button"
        color="primary"
        variant="round"
        @click="showModal = true"
      >
        Show all
      </ChecButton>
    </ChecHeader>
    <dl class="captured-fields__list">
      <template v-for="{ id, name, value } in fieldsExcludingHidden">
        <dt :key="`${id}-summary-key`" class="captured-fields__datum-title">
          {{ name }}
        </dt>
        <dd v-if="!valueIsEmpty(value)" :key="`${id}-summary-value`" class="captured-fields__datum">
          {{ value }}
        </dd>
        <dd
          v-else
          :key="`${id}-summary-value`"
          class="captured-fields__datum captured-fields__datum--empty"
        >
          Empty
        </dd>
      </template>
    </dl>
    <p v-if="hasHiddenFields" class="captured-fields__hidden-notice">
      +{{ hiddenFieldCount }} hidden field{{ hiddenFieldCount !== 1 ? 's' : '' }}
    </p>
    <ChecModal v-if="showModal" header="Captured fields" @dismiss="showModal = false">
      <dl class="captured-fields__list">
        <template v-for="{ id, name, type, value } in fields">
          <dt :key="`${id}-modal-key`" class="captured-fields__datum-title">
            {{ name }}
            <ChecDataPill color="purple">
              {{ type }}
            </ChecDataPill>
          </dt>
          <dd
            v-if="!valueIsEmpty(value)"
            :key="`${id}-summary-value`"
            class="captured-fields__datum"
          >
            {{ value }}
          </dd>
          <dd
            v-else
            :key="`${id}-summary-value`"
            class="captured-fields__datum captured-fields__datum--empty"
          >
            Empty
          </dd>
        </template>
      </dl>
    </ChecModal>
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecDataPill,
  ChecHeader,
  ChecModal,
} from '@chec/ui-library';

export default {
  name: 'CapturedFields',
  components: {
    ChecButton,
    ChecCard,
    ChecDataPill,
    ChecHeader,
    ChecModal,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    hasHiddenFields() {
      return this.fields.some((candidate) => candidate.type === 'hidden');
    },
    fieldsExcludingHidden() {
      return this.fields.filter((candidate) => candidate.type !== 'hidden');
    },
    hiddenFieldCount() {
      return this.fields.length - this.fieldsExcludingHidden.length;
    },
  },
  methods: {
    valueIsEmpty(value) {
      if (typeof value === 'string') {
        return value.trim() === '';
      }
      if (typeof value === 'number') {
        return Number.isNaN(value);
      }

      // Show empty for anything else that we can't cast to a string
      return true;
    },
  },
};
</script>

<style lang="scss">
.captured-fields {
  &__list {
    @apply grid grid-cols-2 row-gap-2 col-gap-2 items-center break-words;
  }

  &__datum-title {
    @apply font-bold;
  }

  &__datum {
    &--empty {
      @apply text-gray-400 italic;
    }
  }

  &__hidden-notice {
    @apply mt-4;
  }
}
</style>
