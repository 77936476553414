var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ChecCard',{attrs:{"tailwind":"p-4","inner-class":"captured-fields"}},[_c('ChecHeader',{staticClass:"pb-4",attrs:{"variant":"card","title":"Captured fields"}},[(_vm.hasHiddenFields)?_c('ChecButton',{staticClass:"card__button",attrs:{"color":"primary","variant":"round"},on:{"click":function($event){_vm.showModal = true}}},[_vm._v(" Show all ")]):_vm._e()],1),_c('dl',{staticClass:"captured-fields__list"},[_vm._l((_vm.fieldsExcludingHidden),function(ref){
var id = ref.id;
var name = ref.name;
var value = ref.value;
return [_c('dt',{key:(id + "-summary-key"),staticClass:"captured-fields__datum-title"},[_vm._v(" "+_vm._s(name)+" ")]),(!_vm.valueIsEmpty(value))?_c('dd',{key:(id + "-summary-value"),staticClass:"captured-fields__datum"},[_vm._v(" "+_vm._s(value)+" ")]):_c('dd',{key:(id + "-summary-value"),staticClass:"captured-fields__datum captured-fields__datum--empty"},[_vm._v(" Empty ")])]})],2),(_vm.hasHiddenFields)?_c('p',{staticClass:"captured-fields__hidden-notice"},[_vm._v(" +"+_vm._s(_vm.hiddenFieldCount)+" hidden field"+_vm._s(_vm.hiddenFieldCount !== 1 ? 's' : '')+" ")]):_vm._e(),(_vm.showModal)?_c('ChecModal',{attrs:{"header":"Captured fields"},on:{"dismiss":function($event){_vm.showModal = false}}},[_c('dl',{staticClass:"captured-fields__list"},[_vm._l((_vm.fields),function(ref){
var id = ref.id;
var name = ref.name;
var type = ref.type;
var value = ref.value;
return [_c('dt',{key:(id + "-modal-key"),staticClass:"captured-fields__datum-title"},[_vm._v(" "+_vm._s(name)+" "),_c('ChecDataPill',{attrs:{"color":"purple"}},[_vm._v(" "+_vm._s(type)+" ")])],1),(!_vm.valueIsEmpty(value))?_c('dd',{key:(id + "-summary-value"),staticClass:"captured-fields__datum"},[_vm._v(" "+_vm._s(value)+" ")]):_c('dd',{key:(id + "-summary-value"),staticClass:"captured-fields__datum captured-fields__datum--empty"},[_vm._v(" Empty ")])]})],2)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }