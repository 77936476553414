<template>
  <ChecCard class="address-card" tailwind="p-4">
    <div v-if="mapImageUrl" class="address-card__map-background">
      <img class="address-card__map-background-image" :src="mapImageUrl">
    </div>
    <ChecHeader class="address-card__header" variant="card" :title="cardTitle">
      <ChecButton
        class="card__button"
        color="primary"
        variant="round"
        @click="() => $emit('button-click')"
      >
        Update
      </ChecButton>
    </ChecHeader>
    <p class="order-detail__card-primary-text">
      {{ address.name || '' }}
    </p>
    <p>
      {{ `${address.street || ''} ${address.street_2 || ''}` }}
    </p>
    <p>
      {{ cityRegionLine }}
    </p>
    <p>
      {{ countryName }}
    </p>
  </ChecCard>
</template>

<script>
import {
  ChecHeader,
  ChecCard,
  ChecButton,
} from '@chec/ui-library';
import fetchMap from '@/lib/helpers/fetchMapbox';

export default {
  name: 'AddressCard',
  components: {
    ChecCard,
    ChecButton,
    ChecHeader,
  },
  props: {
    addressType: {
      type: String,
      validate(value) {
        return ['billing', 'shipping'].includes(value);
      },
      required: true,
    },
    address: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mapBlob: null,
    };
  },
  computed: {
    encodedAddress() {
      return encodeURI(`${this.address.street} ${this.address.town_city} ${this.address.county_state} ${this.address.postal_zip_code}`);
    },
    encodedCountry() {
      return encodeURI(`${this.address.country}`);
    },
    mapImageUrl() {
      return this.mapBlob ? URL.createObjectURL(this.mapBlob) : '';
    },
    /**
     * Maps a country code to a country name if possible, otherwise returns the country code
     *
     * @returns {String}
     */
    countryName() {
      if (this.countries && Object.hasOwnProperty.call(this.countries, this.address.country)) {
        return this.countries[this.address.country]; // E.g. "Canada"
      }
      return this.address.country || ''; // E.g. "CA"
    },
    /**
     * Filters out null values for city, region, and postcode, and concatenates them together with
     * relevant "glue".
     */
    cityRegionLine() {
      const { town_city: city, county_state: region, postal_zip_code: postCode } = this.address;
      const filterFunc = (candidate) => typeof candidate === 'string' && candidate.length > 0;

      return [
        [city, region].filter(filterFunc).join(', '),
        postCode,
      ].filter(filterFunc).join(' ');
    },
    /**
     * The title to display for the address card
     *
     * @returns {String}
     */
    cardTitle() {
      if (this.addressType === 'shipping') {
        return 'Shipping address';
      }
      return 'Billing address';
    },
  },
  created() {
    this.fetchMap(this.encodedAddress, this.encodedCountry).then((blob) => {
      this.mapBlob = blob;
    });
  },
  methods: {
    fetchMap,
  },
};
</script>

<style lang="scss">
.address-card {
  @apply overflow-hidden;

  p {
    @apply relative;
  };

  &__header {
    @apply relative pb-4;
  }

  &__map-background {
    @apply absolute w-full h-full top-0 left-0;

    &::after {
      @apply absolute bg-white opacity-75 w-full h-full;
      content: '';
    }
  }

  &__map-background-image {
    @apply absolute object-cover w-full h-full top-0 left-0;
  }
}
</style>
