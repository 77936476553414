<template>
  <div class="notes">
    <ChecTable>
      <template #prepend>
        <div class="notes__note">
          <TextField
            v-model="newNote"
            type="text"
            name="note"
            :label="$t('notes.addANote')"
            :variant="newNoteError ? 'error' : ''"
            multiline
          />
        </div>
        <div class="notes__toolbar">
          <i18n path="general.loggedInAs" tag="span">
            <template #name>
              <span class="notes__toolbar-name">
                {{ userInfo }}
              </span>
            </template>
          </i18n>
          <ChecButton
            variant="round"
            color="primary"
            size="sm"
            @click="handleAdd"
          >
            {{ $t('notes.add') }}
          </ChecButton>
        </div>
      </template>
      <thead>
        <tr>
          <th>{{ $t('notes.note') }}</th>
          <th>{{ $t('general.options') }}</th>
        </tr>
      </thead>
      <tbody>
        <!-- Loading state -->
        <tr v-if="isLoading">
          <td colspan="2" class="notes__loading-icon">
            <ChecLoading without-background />
          </td>
        </tr>
        <!-- No records -->
        <tr v-else-if="!isLoading && items.length === 0">
          <td colspan="2" class="notes__empty">
            {{ $t('general.empty') }}
          </td>
        </tr>
        <!-- Render the list -->
        <tr v-for="note in items" v-else :key="note.id">
          <td>
            <div class="notes__meta">
              <Timestamp :timestamp="note.created" />
              <span v-if="note.user.name" class="notes__meta-user">
                {{ $t('general.byAuthor', { author: note.user.name }) }}
              </span>
            </div>
            <p class="notes__content">
              {{ note.content }}
            </p>
          </td>
          <td>
            <ChecOptionsMenu class="notes__options">
              <ChecOption destructive @option-selected="handleDelete(note.id)">
                {{ $t('general.delete') }}
              </ChecOption>
            </ChecOptionsMenu>
          </td>
        </tr>
      </tbody>
    </ChecTable>
    <!-- No need to show pagination in this component until there's a second page -->
    <Pagination :state-key="stateKey" :context="context" />
  </div>
</template>

<script>
import {
  ChecButton,
  ChecLoading,
  ChecOption,
  ChecOptionsMenu,
  ChecTable,
  TextField,
} from '@chec/ui-library';
import { mapGetters } from 'vuex';
import withPropDefinedState from '@/mixins/withPropDefinedState';
import Pagination from '@/components/Pagination.vue';
import confirm from '@/mixins/confirm';
import { actions } from '@/lib/pagination';
import isEqual from 'lodash.isequal';
import Timestamp from './Timestamp.vue';

export default {
  name: 'NotesCard',
  components: {
    ChecButton,
    ChecLoading,
    ChecOption,
    ChecOptionsMenu,
    ChecTable,
    Pagination,
    TextField,
    Timestamp,
  },
  mixins: [
    confirm,
    withPropDefinedState({
      state: ['items', 'totalCount', 'isLoading'],
      actions: {
        setPage: actions.SET_PAGE,
        createNote: actions.ADD_NEW_ITEM,
        deleteNote: actions.DELETE_ITEM,
      },
    }),
  ],
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newNote: '',
      newNoteError: false,
    };
  },
  computed: {
    ...mapGetters(['userData', 'userInfo']),
  },
  watch: {
    context(val, oldVal) {
      if (!isEqual(val, oldVal)) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    /**
     * Kick off loading of the notes by setting the page and model ID to load notes for
     */
    init() {
      this.setPage({
        page: 1,
        context: this.context,
      });
    },
    /**
     * Add a new note, prepending it to the list when complete
     */
    handleAdd() {
      if (!this.newNote.length) {
        this.newNoteError = true;
        return;
      }

      this.newNoteError = false;
      this.createNote({
        item: {
          content: this.newNote,
          user: {
            name: this.userInfo,
          },
          created: Date.now() / 1000,
        },
        prepend: true,
      });
      this.newNote = '';
    },
    /**
     * Delete a specific note
     *
     * @param {string} id
     */
    async handleDelete(id) {
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('notes.confirmDelete'),
      )) {
        return;
      }
      this.deleteNote({ id });
    },
  },
};
</script>

<style lang="scss">
.notes {
  &__loading-icon {
    @apply relative p-16;
  }

  &__empty {
    @apply text-gray-400;
  }

  &__note {
    @apply w-full;
  }

  th:last-child,
  td:last-child:not(.notes__empty) {
    @apply align-top text-right;
  }

  &__meta {
    @apply bg-gray-100 rounded p-2 inline-flex items-center;
  }

  &__meta-user {
    @apply ml-1 text-xxs;
  }

  &__options {
    @apply inline-block;
  }

  &__content {
    @apply whitespace-pre-line mt-4 break-words;
  }

  &__toolbar {
    @apply flex justify-between items-center mt-2 text-xs;
  }

  &__toolbar-name {
    @apply font-bold;
  }

  // Default table style overrides/additions
  .chec-table__table {
    @apply w-full;
  }

  .chec-table__inner-wrapper {
    @apply pb-3;
  }
}
</style>
