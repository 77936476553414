<template>
  <ChecTable
    class="order-status-card"
  >
    <tbody>
      <tr>
        <td class="order-status-card--header">
          {{ $t('order.orderStatus') }}
        </td>
        <td>
          <div class="order-status-card--events">
            <ChecDataPill
              :color="order.status !== 'open' ? 'orange' : 'green' "
            >
              {{ order.status }}
            </ChecDataPill>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          {{ $t('order.fulfillment.header') }}
        </td>
        <td>
          <div class="order-status-card--events">
            <ChecDataPill
              :color="fulFillmentPill"
            >
              {{ fulfillmentStatus }}
            </ChecDataPill>
          </div>
        </td>
      </tr>
      <tr>
        <td class="order-status-card--header">
          {{ $t('order.payment') }}
        </td>
        <td>
          <div class="order-status-card--events">
            <ChecDataPill
              :color="paymentPill"
            >
              {{ paymentStatus }}
            </ChecDataPill>
          </div>
        </td>
      </tr>
    </tbody>
  </ChecTable>
</template>

<script>
import {
  ChecDataPill,
  ChecTable,
} from '@chec/ui-library';

export default {
  name: 'StatusCard',
  components: {
    ChecDataPill,
    ChecTable,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {
  //   };
  // },
  computed: {
    fulFillmentPill() {
      const status = this.order.status_payment;
      if (status === 'fulfilled') {
        return 'green';
      }
      if (status === 'returned') {
        return 'gray';
      }
      if (status === 'partially_fulfilled') {
        return 'orange';
      }
      return 'red';
    },
    paymentPill() {
      const status = this.order.status_payment;
      if (status === 'paid') {
        return 'green';
      }
      if (status === 'refunded') {
        return 'gray';
      }
      if (status === 'partially_paid' || status === 'pending') {
        return 'orange';
      }
      return 'red';
    },
    /**
     * The status label for fulfillment on the order, e.g. "Not fulfilled", "Fulfilled", etc.
     *
     * @returns {String}
     */
    fulfillmentStatus() {
      const status = this.order.status_fulfillment;
      if (status === 'fulfilled') {
        return this.$t('general.fulfilled');
      }
      if (status === 'partially_fulfilled') {
        return this.$t('general.partiallyFulfilled');
      }
      return this.$t('general.notFulfilled');
    },
    /**
     * The status label for payment on the order, e.g. "not paid", "paid", etc
     *
     * @returns {String}
     */
    paymentStatus() {
      const status = this.order.status_payment;
      if (status === 'paid') {
        return this.$t('general.paid');
      }
      if (status === 'refunded') {
        return this.$t('general.refunded');
      }
      return this.$t('general.notPaid');
    },
  },
};
</script>

<style lang="scss">
.order-status-card {
  &--events {
    @apply text-right;
  }

  .chec-table__table {
    @apply w-full;
  }
}
</style>
