import {
  string, object,
} from 'yup';
import i18n from '@/lang';

export default object().shape({
  customer: object().shape({
    firstname: string().nullable(),
    lastname: string().nullable(),
    email: string()
      .email(i18n.t('validation.validEmail'))
      .required(i18n.t('validation.enterEmail')),
  }),
});
