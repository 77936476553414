<template>
  <ChecCard tailwind="p-4">
    <ChecHeader class="pb-4" variant="card" :title="$t('order.customerDetails')">
      <ChecButton
        class="card__button"
        color="primary"
        variant="round"
        @click="() => $emit('button-click')"
      >
        {{ $t('general.update') }}
      </ChecButton>
    </ChecHeader>
    <p v-if="customer.firstname" class="order-detail__card-primary-text">
      {{ `${customer.firstname} ${customer.lastname || ''}` }}
    </p>
    <p
      :class="{ 'order-detail__card-primary-text' : !customer.firstname }"
      class="order-detail__customer-email"
    >
      {{ customer.email }}
    </p>
  </ChecCard>
</template>

<script>
import {
  ChecHeader,
  ChecCard,
  ChecButton,
} from '@chec/ui-library';

export default {
  name: 'CustomerCard',
  components: {
    ChecCard,
    ChecButton,
    ChecHeader,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.order-detail {
  &__customer-email {
    @apply break-all;
  }
}
</style>
