<template>
  <div class="order-detail">
    <!-- Portaled to DetailsPageNavigator -->
    <DetailsPagination
      v-if="!isLoading && order"
      :item="order.id"
      route-name="orders.view"
      state-key="orders"
    />
    <template v-if="error">
      <h2>We're having trouble loading this page.</h2>
    </template>
    <template v-else>
      <div class="order-detail__content space-y-4 md:space-y-0">
        <div class="order-detail__main space-y-4">
          <OrderDetails
            v-if="order"
            :order="order"
            @order-refunded="refreshPage"
            @cancel-order="refreshPage"
          />
          <DigitalDelivery
            v-if="downloads"
            :downloads="downloads"
            :order-id="order.id"
            @update="refreshPage"
          />
          <FulfillmentCard
            v-if="order"
            :order="order"
            @fulfill-panel-opened="handleCreateFulfillment"
          />
          <ShipmentsCard
            v-if="order && order.fulfillment && order.fulfillment.physical.shipments.length"
            :order="order"
          />
          <OrderNotesCard
            v-if="order"
            :order="order"
          />
        </div>
        <div class="order-detail__side-bar space-y-4">
          <StatusCard
            v-if="order"
            :order="order"
            @button-click="handleEditOrderStatus"
          />
          <CustomerCard
            v-if="customer"
            :customer="customer"
            @button-click="handleEditCustomer"
          />
          <CapturedFields v-if="capturedFields.length > 0" :fields="capturedFields" />
          <PaymentCard
            v-for="transaction in transactionsList"
            :key="transaction.id"
            :payment="transaction"
          />
          <ManualPaymentCard
            v-for="manualPayment in manualPaymentList"
            :key="manualPayment.id"
            :manual-payment="manualPayment"
            :order="order"
            @manual-payment="refreshPage"
          />
          <AddressCard
            v-if="shipping && order.collected.shipping_address"
            address-type="shipping"
            :address="shipping"
            :countries="countries"
            @button-click="handleEditShipping"
          />
          <AddressCard
            v-if="billing && order.collected.billing_address"
            address-type="billing"
            :address="billing"
            :countries="countries"
            @button-click="handleEditBilling"
          />
        </div>
      </div>
    </template>
    <router-view
      v-if="!isLoading"
      :order="order"
      :countries="countries"
      :subdivisions="subdivisions"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import { getters, actions } from '@/lib/pagination';
import DetailsPagination from '@/components/DetailsPagination.vue';
import mutations from '@/store/mutations';
import CapturedFields from '../components/CapturedFields.vue';
import CustomerCard from '../components/CustomerCard.vue';
import PaymentCard from '../components/PaymentCard.vue';
import ManualPaymentCard from '../components/ManualPaymentCard.vue';
import AddressCard from '../components/AddressCard.vue';
import OrderDetails from '../components/OrderDetails.vue';
import OrderNotesCard from '../components/OrderNotesCard.vue';
import DigitalDelivery from '../components/DigitalDelivery.vue';
import FulfillmentCard from '../components/FulfillmentCard.vue';
import ShipmentsCard from '../components/ShipmentsCard.vue';
import StatusCard from '../components/StatusCard.vue';

export default {
  name: 'OrderView',
  components: {
    StatusCard,
    AddressCard,
    CapturedFields,
    CustomerCard,
    DetailsPagination,
    DigitalDelivery,
    FulfillmentCard,
    ManualPaymentCard,
    OrderDetails,
    OrderNotesCard,
    PaymentCard,
    ShipmentsCard,
  },
  data() {
    return {
      order: null,
    };
  },
  computed: {
    ...mapState('orders', ['error']),
    ...mapGetters('orders', { getOrder: getters.GET_ITEM }),
    ...mapGetters(['isLoading', 'countries', 'subdivisions']),
    customer() {
      return this.order ? this.order.customer : '';
    },
    /**
     * Returns a list of all transactions excluding any that are pending, because we have a
     * separate place to render pending manual transactions, and we haven't got a UI for
     * multiple transactions yet.
     *
     * @returns {Object[]}
     */
    transactionsList() {
      return this.order
        ? this.order.transactions
          .filter((transaction) => transaction.type === 'charge' && transaction.status !== 'pending')
        : [];
    },
    manualPaymentList() {
      return this.order
        ? this.order.transactions
          .filter((transaction) => transaction.type === 'charge' && transaction.status === 'pending' && transaction.gateway === 'manual')
        : [];
    },
    shipping() {
      return (this.order || '')
        && (Object.keys(this.order.shipping).length ? this.order.shipping : '');
    },
    billing() {
      return (this.order || '')
        && (Object.keys(this.order.billing).length ? this.order.billing : '');
    },
    capturedFields() {
      if (!this.order) {
        return [];
      }

      return this.order.extra_fields;
    },
    downloads() {
      if (!this.order || !this.order.fulfillment) {
        return null;
      }

      const { downloads } = this.order.fulfillment.digital;

      if (downloads.length === 0) {
        return null;
      }

      return downloads;
    },
  },
  watch: {
    $route(to) {
      this.order = this.getOrder(to.params.id);
    },
  },
  async created() {
    this.order = this.getOrder(this.$route.params.id);
    if (this.order) {
      return;
    }
    try {
      this[mutations.SET_LOADING](true);
      await this.loadOrder({ id: this.$route.params.id });
      this.order = this.getOrder(this.$route.params.id);
    } catch (error) {
      if (error?.request?.status === 404) {
        this.$router.push({ name: 'notFound' });
      }
    } finally {
      this[mutations.SET_LOADING](false);
    }
  },
  methods: {
    handleEditShipping() {
      return this.$router.push({ name: 'orders.edit.shipping' });
    },
    handleEditBilling() {
      return this.$router.push({ name: 'orders.edit.billing' });
    },
    handleEditCustomer() {
      return this.$router.push({ name: 'orders.edit.customer' });
    },
    handleCreateFulfillment() {
      return this.$router.push({ name: 'orders.fulfill.items' });
    },
    handleEditOrderStatus() {
      return true;
    },
    refreshOrder() {
      this.order = this.getOrder(this.$route.params.id);
    },
    // TODO implement optimistic cache updates in Vuex instead of this
    refreshPage() {
      this.$nextTick(() => {
        window.location.reload();
      });
    },
    ...mapActions('orders', { loadOrder: actions.LOAD_ITEM }),
    ...mapMutations([mutations.SET_LOADING]),
  },
};
</script>

<style lang="scss">
.order-detail {
  @apply text-gray-500;

  &__side-bar {
    @screen lg {
      @apply ml-8 w-1/3 max-w-sm;
    }
  }

  @screen lg {
    &__content {
      @apply flex flex-row;
    }

    &__main {
      @apply flex-grow;
    }
  }

  &__payment-card-transaction-id {
    @apply block font-bold text-xs pt-4 break-all;
  }

  &__payment-card-created-date {
    @apply block;
  }

  &__card-primary-text {
    @apply font-bold text-gray-600;
  }

  &__card-notes {
    @apply pt-2;
  }
}
</style>
