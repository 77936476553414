<template>
  <ChecCard tailwind="p-4">
    <ChecHeader class="pb-4" variant="card" :title="$t('order.paymentMethod')" />
    <p class="order-detail__card-primary-text">
      {{ gatewayName }}
    </p>
    <p>
      {{ referenceText }}
    </p>
    <p
      v-if="payment.gateway_transaction_id"
      class="order-detail__payment-card-transaction-id"
    >
      {{ $t('order.gatewayTransactionId', { id: payment.gateway_transaction_id }) }}
    </p>
    <Timestamp
      v-if="payment.gateway === 'manual'"
      class="order-detail__payment-card-created-date"
      :timestamp="payment.charge_date"
      :prefix="$t('order.capturedOn')"
      from-now
    />
    <div v-if="payment.notes" class="pt-4">
      <ChecDivider>
        {{ $t('general.notes') }}
      </ChecDivider>
      <p class="order-detail__card-notes">
        {{ payment.notes }}
      </p>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecHeader,
  ChecCard,
  ChecDivider,
} from '@chec/ui-library';
import Timestamp from '@/components/Timestamp.vue';

export default {
  name: 'PaymentCard',
  components: {
    ChecCard,
    ChecHeader,
    ChecDivider,
    Timestamp,
  },
  props: {
    payment: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    /**
     * Displays the gateway name, or the manual payment method's "name" e.g.
     * "Cash on delivery"
     *
     * @returns {string}
     */
    gatewayName() {
      return this.payment.gateway === 'manual'
        ? this.payment.payment_source.method_name
        : this.payment.gateway_name;
    },
    /**
     * Gets a summary for reference, e.g. "Card ending in 4242", etc
     *
     * @returns {string}
     */
    referenceText() {
      const { gateway, gateway_reference: reference } = this.payment;

      if (!reference) {
        return '';
      }

      if (gateway !== 'manual' && (
        typeof reference === 'number'
        || (reference.length === 4 && !Number.isNaN(parseInt(reference, 10)))
      )) {
        return this.$t('order.cardEndingIn', { number: reference });
      }

      return this.$t('order.reference', { reference });
    },
  },
};
</script>
