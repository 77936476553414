/**
 * Return a Promise either resolving with a blob of a map or empty string
 *
 * @param {string} encodedAddress
 * @param {string} encodedCountry
 */
export default function fetchMap(encodedAddress, encodedCountry) {
  return fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedAddress}.json?country=${encodedCountry}&types=address&access_token=${process.env.VUE_APP_MAP_BOX_KEY}`)
    .then((response) => response.json())
    .then((data) => {
      if (!Array.isArray(data.features) || !data.features[0]) {
        return '';
      }
      const { center } = data.features[0];
      const [lat, long] = center;
      return fetch(`https://api.mapbox.com/styles/v1/mapbox/light-v9/static/${lat},${long},8,0,0/460x200@2x?access_token=${process.env.VUE_APP_MAP_BOX_KEY}`)
        .then((response) => response.blob());
    });
}
