<template>
  <AddressModal
    header="Change billing address"
    :address="order.billing"
    :countries="countries"
    :subdivisions="subdivisions"
    @close="handleClose"
    @save="handleSave"
  />
</template>

<script>
import { makeApiRequest } from '@/lib/api';
import addNotification from '@/mixins/addNotification';
import AddressModal from '../../components/AddressModal.vue';

export default {
  name: 'ShippingEdit',
  components: {
    AddressModal,
  },
  mixins: [addNotification],
  props: {
    order: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    subdivisions: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleClose(reload = false) {
      this.$router.push({ name: 'orders.view', params: { id: this.order.id } })
        .then(() => {
          if (reload) {
            // TODO implement optimistic cache updates in Vuex instead of this
            window.location.reload();
          }
        });
    },
    handleSave(address) {
      // todo replace this with optimism
      this.$store.commit('SET_LOADING', true);

      makeApiRequest('PUT', `/v1/orders/${this.order.id}`, {
        billing: {
          ...address,
        },
      })
        .then(() => {
          this.handleClose(true);
          this.addNotification(this.$t('order.updatedBilling'));
        })
        .catch(() => {
          this.addNotification(this.$t('order.updateAddressError'), 'error');
        })
        .finally(() => {
          // todo replace this with optimism
          this.$store.commit('SET_LOADING', false);
        });
    },
  },
};
</script>
