// TO-DO: Add shipping carriers to API

export default [
  {
    value: 'usps',
    label: 'USPS',
  },
  {
    value: 'ups',
    label: 'UPS',
  },
  {
    value: 'fedex',
    label: 'FedEx',
  },
  {
    value: 'dhl_global',
    label: 'DHL Global',
  },
  {
    value: 'dhl_express',
    label: 'DHL Express',
  },
  {
    value: 'dhl_us',
    label: 'DHL US',
  },
  {
    value: 'ontrac',
    label: 'OnTrac',
  },
  {
    value: 'canada_post',
    label: 'CANADA POST',
  },
  {
    value: 'dpd',
    label: 'DPD',
  },
  {
    value: 'royal_mail',
    label: 'Royal Mail',
  },
  {
    value: 'parcel_force',
    label: 'Parcel Force',
  },
  {
    value: 'tnt',
    label: 'TNT',
  },
  {
    value: 'deutsche_post',
    label: 'Deutsche Post',
  },
  {
    value: 'la_poste',
    label: 'LA POSTE',
  },
  {
    value: 'australia_post',
    label: 'Australia Post',
  },
  {
    value: 'toll',
    label: 'Toll',
  },
  {
    value: 'tipsa',
    label: 'TIPSA',
  },
  {
    value: 'israel_post',
    label: 'Israel Post',
  },
  {
    value: 'ceska_posta',
    label: 'Česká pošta',
  },
  {
    value: 'other',
    label: 'Other',
  },
];
