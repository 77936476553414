<template>
  <ChecSlideoutPanel
    :title="$t('order.fulfillItems')"
    close-on-overlay-click
    @close="handleClose"
  >
    <ChecLoading v-if="loading" />
    <div v-else class="fulfillment__panel">
      <table class="fulfillment__items">
        <thead>
          <tr>
            <th>Item</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in fulfillmentItemsWithQtyRemaining"
            :key="item.line_item_id"
          >
            <td>
              {{ item.product_name }}
              <div
                v-if="getSelectedOptions(item).length"
                class="fulfillment__item-variants"
              >
                <span
                  v-for="selectedOption in getSelectedOptions(item)"
                  :key="selectedOption.group_id"
                  class="fulfillment__item-variant"
                >
                  {{ selectedOption.group_name }}:
                  <span class="fulfillment__item-variant-name">
                    {{ selectedOption.option_name }}
                  </span>
                </span>
              </div>
            </td>
            <td>
              <div class="fulfillment__item-quantity">
                <TextField
                  v-if="fulfillmentData.line_items[index]"
                  v-model="fulfillmentData.line_items[index].quantity"
                  label="Fulfill"
                  name="quantity"
                  class="fulfillment__item-quantity--field"
                >
                  of {{ item.quantity.remaining }}
                </TextField>
                <span
                  v-if="validationErrors[`line_items[${index}].quantity`]"
                  class="input-wrapper__error"
                >
                  {{ validationErrors[`line_items[${index}].quantity`] }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="input-wrapper__row space-x-2">
        <div class="input-wrapper">
          <ChecDropdown
            v-model="fulfillmentData.carrier"
            :label="$t('order.fulfillment.shippingCarrier')"
            name="carrier"
            :options="shippingCarriers"
          />
        </div>
        <div class="input-wrapper">
          <TextField
            v-model="fulfillmentData.tracking_number"
            :label="$t('order.fulfillment.trackingNumberOptional')"
            name="tracking_number"
            :disabled="fulfillmentData.carrier === ''"
          />
        </div>
      </div>
    </div>
    <template #toolbar>
      <ChecButton color="primary" text-only @click="handleClose">
        {{ $t('general.cancel') }}
      </ChecButton>
      <ChecButton
        color="primary"
        button-type="submit"
        :disabled="loading"
        @click.prevent="handleSave"
      >
        {{ loading ? $t('general.loading') : $t('general.saveChanges') }}
      </ChecButton>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  ChecSlideoutPanel,
  TextField,
  ChecButton,
  ChecDropdown,
  ChecLoading,
} from '@chec/ui-library';
import addNotification from '@/mixins/addNotification';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import physicalShipmentsSchema from '../schemas/physicalShipments';
import shippingCarriers from '../lib/shippingCarriers';

export default {
  name: 'FulfillmentPanel',
  components: {
    ChecSlideoutPanel,
    TextField,
    ChecButton,
    ChecDropdown,
    ChecLoading,
  },
  mixins: [addNotification],
  props: {
    order: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      fulfillmentData: {
        line_items: {},
        carrier: '',
        tracking_number: '',
      },
      shippingCarriers,
      validationErrors: {},
    };
  },
  computed: {
    /**
     * Returns the fufillment items with quantity remaining of more than 0
     *
     * @returns {Array}
     */
    fulfillmentItemsWithQtyRemaining() {
      return this.order.fulfillment.physical.items.filter((item) => item.quantity.remaining > 0);
    },
  },
  /**
   * Mounts quantity remaining to fulfill input
   */
  mounted() {
    this.fulfillmentData.line_items = this.fulfillmentItemsWithQtyRemaining.map((item) => ({
      id: item.line_item_id,
      quantity: item.quantity.remaining || 1,
    }));
  },
  methods: {
    getSelectedOptions(item) {
      // @see https://github.com/chec/api.v1/issues/1242
      // todo remove this when the API is built with proper data relationships
      if (item.variants && item.variants.length) {
        return item.variants.map((variant) => ({
          ...variant,
          group_id: variant.variant_id,
          group_name: variant.variant_name,
        }));
      }

      if (item.selected_options && item.selected_options.length) {
        return item.selected_options;
      }

      return [];
    },
    handleClose() {
      this.$emit('close');
    },
    handleSave() {
      this.validationErrors = {};
      validateSchemaRequest(physicalShipmentsSchema, this.fulfillmentData, { abortEarly: false })
        .then((validatedData) => {
          this.$emit('save', validatedData);
        })
        .catch((error) => {
          this.validationErrors = error.errors;
        });
    },
  },
};
</script>

<style lang="scss">
.fulfillment__panel {
  .fulfillment {
    &__items {
      @apply mb-3;
    }

    &__item-quantity {
      @apply flex flex-col items-end;

      &--field {
        @apply w-full float-right;
        max-width: 8rem;
      }

      .input-wrapper__error {
        @apply pt-2;
      }
    }
  }
}
</style>
