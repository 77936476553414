import {
  object,
  string,
} from 'yup';

export default object().shape({
  date: string().nullable(),
  notes: string().nullable(),
  time: string().nullable(),
  transactionId: string().nullable(),
});
