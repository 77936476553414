<template>
  <ChecCard
    v-if="order.status_fulfillment !== 'fulfilled' && order.has.physical_fulfillment"
    tailwind="p-4 mb-4"
  >
    <ChecHeader
      variant="card"
      :title="`${order.is.fulfilled ? this.$t('general.fulfilled') : this.$t('general.unfulfilled')}
      (${sumFulfillmentItemsRemainingQty})`"
    >
      <ChecButton
        v-if="order.status_fulfillment !== 'fulfilled' && order.status !== 'cancelled'"
        color="primary"
        variant="round"
        @click="() => $emit('fulfill-panel-opened')"
      >
        {{ $t('general.fulfill') }}
      </ChecButton>
    </ChecHeader>

    <table class="fulfillment__items">
      <thead>
        <tr>
          <th>{{ $t('general.item') }}</th>
          <th>{{ $t('general.quantity') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in fulfillmentItemsWithQtyRemaining"
          :key="item.id"
        >
          <td>
            {{ item.product_name }}
            <div
              v-if="getSelectedOptions(item).length"
              class="fulfillment__item-variants"
            >
              <span
                v-for="selectedOption in getSelectedOptions(item)"
                :key="selectedOption.group_id"
                class="fulfillment__item-variant"
              >
                {{ selectedOption.group_name }}:
                <span class="fulfillment__item-variant-name">{{ selectedOption.option_name }}</span>
              </span>
            </div>
          </td>
          <td class="fulfillment__item-qty">
            {{ $t('general.xOfY', { x: item.quantity.remaining, y: item.quantity.total }) }}
          </td>
        </tr>
      </tbody>
    </table>
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecHeader,
} from '@chec/ui-library';

export default {
  name: 'FulfillmentCard',
  components: {
    ChecCard,
    ChecButton,
    ChecHeader,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    /**
     * Returns the fufillment items with quantity remaining of more than 0
     *
     * @returns {object}
     */
    fulfillmentItemsWithQtyRemaining() {
      return this.order.fulfillment.physical.items.filter((item) => item.quantity.remaining > 0);
    },
    /**
     * Returns the total number of fulfillment items remaining
     *
     * @returns {number}
     */
    sumFulfillmentItemsRemainingQty() {
      return this.order.fulfillment.physical.items
        .map((item) => item.quantity.remaining)
        .reduce((acc, curr) => acc + curr, 0);
    },
  },
  methods: {
    getSelectedOptions(item) {
      // @see https://github.com/chec/api.v1/issues/1242
      // todo remove this when the API is built with proper data relationships
      if (item.variants && item.variants.length) {
        return item.variants.map((variant) => ({
          ...variant,
          group_id: variant.variant_id,
          group_name: variant.variant_name,
        }));
      }

      if (item.selected_options && item.selected_options.length) {
        return item.selected_options;
      }

      return [];
    },
  },
};
</script>

<style lang="scss">
.fulfillment {
  &__items {
    @apply w-full text-left mt-4 text-gray-600;

    tr th:nth-child(n+2),
    tr td:nth-child(n+2) {
      @apply text-right;
    }
  }

  &__item-variants {
    @apply flex mt-2 caps-xxs font-normal;
  }

  &__item-variant {
    @apply flex;

    &:not(:last-child)::after {
      @apply text-gray-300 font-mono text-4xl relative;
      bottom: -5px;
      content: '\2219'; // hex for bullet operator
      line-height: 0;
    }
  }

  &__item-variant-name {
    @apply ml-1 font-bold;
  }

  &__item-amount {
    @apply text-green-600;
  }
}
</style>
