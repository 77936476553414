<template>
  <FulfillmentPanel
    v-if="order"
    :order="order"
    :loading="loading"
    @close="handleClose"
    @save="handleSave"
  />
</template>

<script>
import { makeApiRequest } from '@/lib/api';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import FulfillmentPanel from '../../components/FulfillmentPanel.vue';

export default {
  name: 'FulfillmentCreate',
  components: {
    FulfillmentPanel,
  },
  mixins: [addNotification, confirm],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleClose(reload = false) {
      this.$router.push({ name: 'orders.view', params: { id: this.order.id } })
        .then(() => {
          if (reload) {
            // TODO implement optimistic cache updates in Vuex instead of this
            window.location.reload();
          }
        });
    },
    async handleSave(data) {
      if (!await this.confirm(
        this.$t('order.fulfillment.confirmHeader'),
        this.$t('order.fulfillment.confirmCopy'),
      )) {
        return;
      }

      this.loading = true;

      makeApiRequest('POST', `/v1/orders/${this.order.id}/fulfillments/physical/shipments`, data)
        .then(() => {
          this.addNotification(this.$t('order.itemFulfilled'));
          this.handleClose(true);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            // Temporarily using API response message to render in the error notification window
            this.addNotification(error.response.data.error.message, 'error');
            return;
          }
          this.addNotification(this.$t('general.error'), 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
