import {
  object,
  string,
  array,
  number,
} from 'yup';

export default object().shape({
  carrier: string(),
  tracking_number: string(),
  line_items: array().of(
    object().shape({
      id: string(),
      quantity: number('Please enter a valid number').min(0, 'Quantity must be at least 0'),
    }),
  ),
});
