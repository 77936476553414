<template>
  <ChecCard
    tailwind="p-4 mb-4"
    inner-class="order-notes"
  >
    <ChecHeader variant="card" :title="$t('order.orderNotes')" />
    <NotesCard
      state-key="orders/notes"
      :context="{ orderId: order.id }"
    />
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecHeader,
} from '@chec/ui-library';
import NotesCard from '@/components/NotesCard.vue';

export default {
  name: 'OrderNotesCard',
  components: {
    ChecCard,
    ChecHeader,
    NotesCard,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.order-notes .notes {
  @apply mt-4;
}
</style>
