<template>
  <ChecCard
    inner-class="shipments__carrier"
    tailwind="p-4"
  >
    <div class="shipments__carrier-details">
      <h4 class="shipments__carrier-title">
        {{ shipment.carrier_name }}
      </h4>
      <span v-if="shipment.tracking_number">
        {{ $t('order.fulfillment.trackingNumber', { number: shipment.tracking_number }) }}
      </span>
      <span v-else>{{ $t('order.fulfillment.trackingNotProvided') }}</span>
    </div>
    <div class="shipments__carrier-logo">
      <img
        v-if="shipment.carrier"
        :src="`/img/carrier-logos/${shipment.carrier}.svg`"
        :alt="`Image of ${shipment.carrier_name}`"
      >
    </div>
  </ChecCard>
</template>

<script>
import { ChecCard } from '@chec/ui-library';

export default {
  name: 'Shipment',
  components: {
    ChecCard,
  },
  props: {
    shipment: {
      type: Object,
      required: true,
    },
  },
};
</script>
